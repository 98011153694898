<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="secondary" (click)="dismiss()" fill="outline">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'LABEL.deviceInfo' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list class="info-list" lines="full">

    <ion-item>
      <ion-label text-wrap>Offline Mode</ion-label>
      <ion-toggle [(ngModel)]="offlineMode" (ionChange)="toggleOfflineMode()"></ion-toggle>
    </ion-item>

    <ion-item-divider>
      <ion-label text-wrap>Build Info</ion-label>
    </ion-item-divider>

    <ion-item>
      <ion-note text-wrap slot="start">App</ion-note>
      <ion-label text-wrap>{{ appName }}</ion-label>
    </ion-item>

    <ion-item>
      <ion-note text-wrap slot="start">Channel</ion-note>
      <ion-label text-wrap>{{ snapshotInfo?.channel || 'none' }}</ion-label>
    </ion-item>

    <ion-item>
      <ion-note text-wrap slot="start">Appflow Build</ion-note>
      <ion-label text-wrap>{{ snapshotInfo?.buildId || 'none' }}</ion-label>
    </ion-item>

    <ion-item>
      <ion-note text-wrap slot="start">Binary Version</ion-note>
      <ion-label text-wrap>{{ snapshotInfo?.binaryVersionCode || 'none' }}</ion-label>
    </ion-item>

    <ion-item>
      <ion-note text-wrap slot="start">Internal Version</ion-note>
      <ion-label text-wrap>{{ buildInfo.internalVersion || 'none'}}</ion-label>
    </ion-item>

    <ion-item>
      <ion-note text-wrap slot="start">Build Date</ion-note>
      <ion-label text-wrap>{{ buildInfo.date || 'none'}}</ion-label>
    </ion-item>

    <ng-container *ngFor="let property of deviceProperties">
      <ion-item-divider *ngIf="property.name === 'Spacer'">
        <ion-label text-wrap>{{ property.value }}</ion-label>
      </ion-item-divider>

      <ion-item *ngIf="property.name !== 'Spacer'">
        <ion-note text-wrap slot="start">{{ property.name }}</ion-note>
        <ion-label text-wrap>{{ property.value }}</ion-label>
      </ion-item>
    </ng-container>


    <ion-item>
      <ion-note text-wrap>Dispatch URL</ion-note>
      <ion-label text-wrap>{{ currentDispatcherUrl }}</ion-label>
    </ion-item>

    <ion-item-group>
      <ion-item-divider>
        <ion-label text-wrap>Debug</ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-note text-wrap>Set Background Mode</ion-note>
        <ion-label text-wrap>
          {{ bring2frontPermission ? "GRANTED" : "DENIED" }}
        </ion-label>
        <ion-button (click)="setBackgroundMode()" slot="end">
          {{ 'ACTION.go' | translate }}
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-note text-wrap>Test Bring2Front</ion-note>
        <ion-label text-wrap>
          {{ bring2frontPermission ? "GRANTED" : "DENIED" }}
        </ion-label>
        <ion-button (click)="testBring2Front()" slot="end">
          {{ 'ACTION.go' | translate }}
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-label text-wrap>Debug Sounds</ion-label>
        <ion-button (click)="toggleDebug()" slot="end">
          {{ 'ACTION.toggle' | translate }}
        </ion-button>
      </ion-item>

      <ion-item-divider>
        <ion-label text-wrap>Error Testing</ion-label>
      </ion-item-divider>

      <ion-item>
        <ion-label text-wrap>Test Error</ion-label>
        <ion-button color="danger" (click)="createError()" slot="end">
          Throw
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-label text-wrap>Throw Error</ion-label>
        <ion-button color="danger" (click)="throwError()" slot="end">
          Throw
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-label text-wrap>Unhandled Error</ion-label>
        <ion-button color="danger" (click)="unhandledError()" slot="end">
          Throw
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-label text-wrap>Try/Catch Error</ion-label>
        <ion-button color="danger" (click)="tryCatchError()" slot="end">
          Throw
        </ion-button>
      </ion-item>

    </ion-item-group>
  </ion-list>

</ion-content>
