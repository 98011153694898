<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'TITLE.scanPass' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item fill="outline">
    <ion-label position="stacked">{{ 'TITLE.scanPass' | translate }}</ion-label>
    <ion-input type="text" (keyup)="passScanned()" [(ngModel)]="passId" #passInput />
  </ion-item>
</ion-content>

<ion-footer>
  <ion-button color="primary" expand="block" (click)="dismiss()">
    {{ 'ACTION.done' | translate }}
  </ion-button>
</ion-footer>
