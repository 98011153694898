<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'TITLE.groupStop' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <section *ngIf="dropOffs.length" class="dropOffs">
    <h3>{{ 'LABEL.dropOffs' | translate }}</h3>
    <div class="card dropoff" *ngFor="let job of dropOffs">
      <ion-button
        type="button"
        slot="start"
        class="label"
        size="small"
        fill="clear"
        expand="block"
        [attr.aria-label]="'LABEL.tapForDetails' | translate"
        (click)="showJobDetails(job)"
      >
        <span class="rider">{{ handleName(job.riderFirstName, job.riderLastName) }}</span>
        <ion-icon name="expand"></ion-icon>
      </ion-button>

      <div>
        <ion-button
          type="button"
          color="primary"
          [attr.aria-label]="'ACTION.dropOff' | translate"
          (click)="handleDropOff(job)"
        >
          <ion-icon aria-hidden name="trending-down"></ion-icon>
        </ion-button>
      </div>
    </div>
  </section>

  <section *ngIf="pickups.length" class="pickups">
    <h3>{{ 'LABEL.pickups' | translate }}</h3>
    <div class="card pickup" *ngFor="let job of pickups">
      <ion-button
        type="button"
        slot="start"
        class="label"
        size="small"
        fill="clear"
        expand="block"
        [attr.aria-label]="'LABEL.tapForDetails' | translate"
        (click)="showJobDetails(job)"
      >
        <span class="rider">{{ handleName(job.riderFirstName, job.riderLastName) }}</span>
        <ion-icon name="expand"></ion-icon>
      </ion-button>

      <div>
        <ion-button
          type="button"
          color="success"
          [attr.aria-label]="'ACTION.onboard' | translate"
          (click)="handleOnBoard(job)"
        >
          <ion-icon aria-hidden name="checkmark-circle"></ion-icon>
        </ion-button>
        <ion-button
          type="button"
          color="danger"
          [attr.aria-label]="'ACTION.noShow' | translate"
          (click)="handleNoShow(job)"
        >
          <ion-icon aria-hidden name="close-circle"></ion-icon>
        </ion-button>
      </div>
    </div>
  </section>

</ion-content>

<ion-footer>
  <ion-button type="button" fill="outline" expand="block" (click)="onCancel()">{{ 'ACTION.cancel' | translate }}</ion-button>
</ion-footer>
