import { FlagStopPassScan } from './flag-stop';

export type RiderTypePerform = {
  riderTypeId: number;
  isActive: boolean;
  riderTypeDescription: string;
  initialNumberOnboard: number;
  passlessBoardedCount: number;
  passlessDebordedCount: number;
  defaultFare: number;
  totalPasslessFareCollected: number;
  scannedPasses: FlagStopPassScan[];
  initialPassesOnboard: string[];
}

export function totalOnboard({ initialNumberOnboard = 0, passlessBoardedCount = 0, scannedPasses = [], passlessDebordedCount = 0 }: RiderTypePerform): number {
  return initialNumberOnboard // initial passenger count at the stop
    + passlessBoardedCount // number of passengers boarded without a pass
    + scannedPasses.filter(t => t.isPickup).length // number of passengers boarded with a pass
    - passlessDebordedCount // number of passengers deboarded without a pass
    - scannedPasses.filter(t => !t.isPickup).length;
}

export function totalBoardedAtThisStop({ passlessBoardedCount = 0, scannedPasses = [] }: RiderTypePerform): number {
  return passlessBoardedCount // number of passengers boarded without a pass
    + scannedPasses.filter(t => t.isPickup).length; // number of passengers boarded with a pass
}

export function totalDeboardedAtThisStop({ passlessDebordedCount = 0, scannedPasses = [] }: RiderTypePerform): number {
  return passlessDebordedCount // number of passengers boarded without a pass
    + scannedPasses.filter(t => !t.isPickup).length; // number of passengers boarded with a pass
}

export function totalPassFareCollected({ scannedPasses = []}: RiderTypePerform): number {
  const passPickups = scannedPasses.filter(t => t.isPickup);
  return passPickups.length === 0
    ? 0
    : scannedPasses.filter(t => t.isPickup)
      .map(t => t.cashFareCollected)
      .reduce((sum, current) => sum + current);
}

export function totalPassesBoardedAtThisStop({ scannedPasses = [] }: RiderTypePerform): number {
  return scannedPasses.filter(t => t.isPickup).length;
}

export function totalPassesDeboardedAtThisStop({ scannedPasses = [] }): number {
  return scannedPasses.filter(t => !t.isPickup).length;
}
