export type PaymentInfo = {
  hasPayment: boolean;
  isSuccessful?: boolean;
  type: PaymentMethodType
}

export function createPaymentInfo (paymentInfo: boolean | undefined): PaymentInfo{
  return {
    hasPayment: paymentInfo !== undefined,
    isSuccessful: paymentInfo === true,
    type: PaymentMethodType.CreditCard,
  };
}

export enum PaymentMethodType {
  CreditCard = "creditcard"
}
