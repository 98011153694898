import { JobArrive, JobPerform, OnTheWay } from './job';

import { CallRequest } from './call-request';
import { CompleteRoute } from './complete-route';
import { FairmaticStatusChange } from './fairmatic-status-change';
import { FlagStopPerform } from './flag-stop';
import { Fluids } from './fluids';
import { GpsReport } from './gps-report';
import { InspectionItemResult } from './inspection';
import { Logout } from './logout';
import { MessageResponse } from './message-response';
import { SendPredefinedMessage } from './send-predefined-message';

export type RouteStatusUpdate = {
  sessionId: string;
  retryCount: number;
  messageId: string;
  locationSentSinceLastUpdate: boolean;
  gpsReports: GpsReport[];
  jobArrivals: JobArrive[];
  jobPerforms: JobPerform[];
  predefinedMessages: SendPredefinedMessage[];
  messageResponses: MessageResponse[];
  callRequests: CallRequest[];
  breakStart: GpsReport;
  breakEnd: GpsReport;
  preTripStart: GpsReport;
  preTripEnd: GpsReport;
  postTripStart: GpsReport;
  postTripEnd: GpsReport;
  preTripInspection: InspectionItemResult[];
  postTripInspection: InspectionItemResult[];
  fluids: Fluids;
  completeRoute: CompleteRoute;
  logout: Logout;
  flagStopPerforms: FlagStopPerform[];
  onTheWays: OnTheWay[];
  errorMessages: string[];
  fairmaticStatusEvents: FairmaticStatusChange[];
  pullOutPerform?: PullPerform;
  pullInPerform?: PullPerform;
}

export type PullPerform = {
  performTime: Date;
  gpsReport: GpsReport;
}

export type RouteStatusUpdateResult = {
  loggedOutDate: Date;
  driverFirstName: string;
  driverLastName: string;
  vehicleNumber: string;
  routeNumber: number;
  needUpdateFromServer: boolean;
}

export function concatStatusUpdates(route: RouteStatusUpdate, update: RouteStatusUpdate) {
  route.callRequests = route.callRequests?.concat(update.callRequests || []) || [];
  route.gpsReports = route.gpsReports.concat(update.gpsReports);
  route.jobPerforms = route.jobPerforms.concat(update.jobPerforms);
  route.messageResponses = route.messageResponses.concat(update.messageResponses);
  route.predefinedMessages = route.predefinedMessages.concat(update.predefinedMessages);
  route.jobArrivals = route.jobArrivals.concat(update.jobArrivals);

  route.preTripInspection = route.preTripInspection.concat(update.preTripInspection);
  route.postTripInspection = route.postTripInspection.concat(update.postTripInspection);

  route.flagStopPerforms = route.flagStopPerforms.concat(update.flagStopPerforms);
  route.onTheWays = route.onTheWays.concat(update.onTheWays);

  route.errorMessages = route.errorMessages.concat(update.errorMessages);
  route.fairmaticStatusEvents = route.fairmaticStatusEvents.concat(update.fairmaticStatusEvents);

  [ 'fluids', 'pullOutPerform', 'breakStart', 'breakEnd', 'preTripStart', 'preTripEnd', 'postTripStart', 'postTripEnd', 'pullInPerform', 'completeRoute', 'logout' ]
  .forEach(prop => {
    if (!route[prop] && update[prop]) { route[prop] = update[prop]; }
  });

  return route;
}

export function createNewStatusUpdate(sessionId: string = '') {
  return {
    sessionId,
    retryCount: 0,
    messageId: null,
    locationSentSinceLastUpdate: true,
    callRequests: [],
    gpsReports: [],
    jobPerforms: [],
    messageResponses: [],
    predefinedMessages: [],
    jobArrivals: [],
    fluids: null,
    breakStart: null,
    breakEnd: null,
    preTripStart: null,
    preTripEnd: null,
    preTripInspection: [],
    postTripStart: null,
    postTripEnd: null,
    postTripInspection: [],
    completeRoute: null,
    logout: null,
    flagStopPerforms: [],
    onTheWays: [],
    errorMessages: [],
    fairmaticStatusEvents: [],
    pullOutPerform: null,
    pullInPerform: null
  };
}
