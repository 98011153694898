import { AlertController, AlertOptions } from '@ionic/angular';
import { Injectable, inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
/** A service that gives shortcut functions to launch ionic alerts. */
export class AlertService {

  private readonly alertController = inject(AlertController);

  async initialize() {
    console.info('AlertService.initialize()');
    // initialize the alert controller so that it is ready to go when we need it.
    // this is a workaround for a bug in ionic 4.0.0-beta.19
    try {
      await this.alertController.create();
    } catch (err) { console.error('An error may occur when the alert controller loads resources without a connection (with live reload)', err); }
  }

  /**
   * Present an alert to the user, along with an optional onDismiss function.
   * @param options (optional) the AlertOptions to pass to the alert controller
   * @param onDismiss (optional) an onDismiss function to call when the alert is closed.
   */
  async presentAlert(options: AlertOptions | undefined, onDismiss: (() => void) | undefined = undefined) {
    const alertObj = await this.alertController.create(options);
    // remove the alert from the array when it closes.
    alertObj.onDidDismiss().then(() => { if (onDismiss) onDismiss(); });
    return alertObj.present();
  }

  /**
   * Present an alert to the user and wait (along with an optional onDismiss function).
   * @param options (optional) the AlertOptions to pass to the alert controller
   * @param onDismiss (optional) an onDismiss function to call when the alert is closed.
   */
  async presentAlertAsync(options: AlertOptions | undefined, onDismiss: (() => void) | undefined = undefined): Promise<void> {
    const alertObj = await this.alertController.create(options);
    await alertObj.present();
    alertObj.onDidDismiss().then(() => { if (onDismiss) onDismiss(); });
  }
}
