<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'LABEL.selectMessage' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form id="messageForm" [formGroup]="form" (submit)="onSubmit()">
    <ion-radio-group formControlName="message">
      <ion-item *ngFor="let message of messages">
        <ion-label>{{ message.displayValue }}</ion-label>
        <ion-radio slot="end" [value]="message.id"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </form>
</ion-content>
<ion-footer>
  <ion-button
    type="button"
    fill="outline"
    (click)="close()"
  >{{ 'ACTION.cancel' | translate }}</ion-button>
  <ion-button
    form="messageForm"
    type="submit"
    color="primary"
    fill="solid"
    [disabled]="form.invalid || submitting"
  >{{ 'ACTION.ok' | translate }}</ion-button>
</ion-footer>
