import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {LoginRequiredGuardService} from './services/login-required-guard.service';
import {NgModule} from '@angular/core';
//import {IonicStorageModule} from '@ionic/storage';
import {RouteRequiredGuardService} from './services/route-required-guard.service';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'customer-settings',
        loadChildren: () => import('./pages/customer-settings/customer-settings.module').then(m => m.CustomerSettingsPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'route-preview',
        loadChildren: () => import('./pages/route-preview/route-preview.module').then(m => m.RoutePreviewPageModule),
        canActivate: [LoginRequiredGuardService],
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [RouteRequiredGuardService],
    },
    {
        path: '',
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
        canActivate: [RouteRequiredGuardService],
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule),

    },
  { path: 'sandbox', loadChildren: () => import('./pages/sandbox/sandbox.module').then(m => m.SandboxPageModule) },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
