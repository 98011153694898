import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {

  public readonly scrollTop$ = new Subject<boolean>();

  public static circularRefReplacement: any = '◯';
  public static nativeObjReplacement: any = '🏹';
  public static functionReplacement: any = '⨐';
  public static maxDepthReplacement: any = '☋';
  public static ignorePrefixes: string[] = ['__', '[['];
  public static allowDateObjects = true;
  public static allowErrorObjects = true;
  public static allowNativeObjects = false;

  isNullOrUndefined(o: any) {
    return (o === null || o === undefined);
  }

  generateGuid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  safeClone(obj: any, maxDepth: number = -1) {
    maxDepth = maxDepth || 0;
    const refs = [];
    let cloned = obj;
    if (Array.isArray(obj)) {
      cloned = this.cloneArray(obj, refs, 0, maxDepth);
    } else if (typeof (obj) === 'object' && obj !== null) {
      cloned = this.cloneObject(obj, refs, 0, maxDepth);
    }
    return cloned;
  }

  private cloneObject(obj: object, refs: any[], depth: number, maxDepth: number) {
    if (!UtilityService.allowNativeObjects && this.hasNativeConstructor(obj)) {
      if (UtilityService.allowDateObjects && obj.constructor === Date) {
        return new Date((obj as Date).getTime());
      } else if (UtilityService.allowErrorObjects && obj instanceof Error) {
        obj = {
          name: obj['name'],
          message: obj['message'],
          stack: obj['stack'],
        };
      } else {
        return UtilityService.nativeObjReplacement;
      }
    } else if (maxDepth > 0 && depth > maxDepth) {
      return UtilityService.maxDepthReplacement;
    }
    let cloned = obj;
    if (!Array.isArray(obj) && typeof (obj) === 'object' && obj !== null) {
      cloned = {};
      refs.push(obj);
      for (const prop in obj) { // tslint:disable-line
        let skip = typeof (prop) !== 'string';
        if (!skip) {
          for (const pfx of UtilityService.ignorePrefixes) {
            if (prop.startsWith(pfx)) {
              skip = true;
              break;
            }
          }
        }
        if (skip) {
          continue;
        }
        const val = obj[prop],
          type = typeof (obj[prop]),
          isArray = Array.isArray(obj[prop]);
        const isRef = (isArray || type === 'object' || type === 'function') && val !== null;
        if (isRef) {
          const idx = refs.indexOf(val);
          if (idx > -1) {
            cloned[prop] = UtilityService.circularRefReplacement;
          } else if (isArray) {
            cloned[prop] = this.cloneArray(val, refs, depth + 1, maxDepth);
          } else if (type === 'object') {
            cloned[prop] = this.cloneObject(val, refs, depth + 1, maxDepth);
          } else {
            cloned[prop] = UtilityService.functionReplacement;
          }
        } else {
          cloned[prop] = val;
        }
      }
      refs.pop();
    }
    return cloned;
  }

  private cloneArray(arr: any[], refs: any[], depth: number, maxDepth: number) {
    if (maxDepth > 0 && depth > maxDepth) {
      return UtilityService.maxDepthReplacement;
    }
    let cloned = arr;
    if (Array.isArray(arr)) {
      cloned = [];
      refs.push(arr);
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const type = typeof (item),
          isItemArray = Array.isArray(item);
        const isRef = (isItemArray || type === 'object' || type === 'function') && item !== null;
        if (isRef) {
          const idx = refs.indexOf(item);
          if (idx > -1) {
            cloned.push(UtilityService.circularRefReplacement);
          } else if (isItemArray) {
            cloned.push(this.cloneArray(item, refs, depth + 1, maxDepth));
          } else if (typeof (item) === 'object' && item !== null) {
            cloned.push(this.cloneObject(item, refs, depth + 1, maxDepth));
          } else {
            cloned.push(UtilityService.functionReplacement);
          }
        } else {
          cloned.push(item);
        }
      }
      refs.pop();
    }
    return cloned;
  }

  private hasNativeConstructor(obj: any) {
    if (obj.constructor && obj.constructor !== Object) {
      const cstrAsString = ('' + obj.constructor).replace(/\s/g, '');
      return cstrAsString.endsWith('{[nativecode]}');
    }
    return false;
  }

  /** Based on Environment variable, returned correctly formatted name */
  public handleName(first: string, last: string): string {
    if (!first && !last) { return ''; }
    if (environment.features.trimName) return `${last}, ${first?.[0]}.`;
    return `${last}, ${first}`;
  }

  /** Because in the notifications we do first last unlike the rest of the applications */
  public handleNameForNotification(first: string, last: string): string {
    if (!first && !last) { return ''; }
    if (environment.features.trimName) return `${first?.[0]}. ${last}`;
    return `${first} ${last}`;
  }

}
