<ion-header>
  <ion-toolbar>
    <ion-title>App Permissions</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p>{{ appName }} will ask you for the following permissions:</p>
  <dl *ngIf="permissions$ | async as $">



    <dt>
      <ng-container *ngTemplateOutlet="icon; context: { $implicit: $.permissions.camera }" />
      Camera
    </dt>
    <dd>Needed to scan passes</dd>

    <dt>
      <ng-container *ngTemplateOutlet="icon; context: { $implicit: $.permissions.displayOverOther }" />
      Draw Over
    </dt>
    <dd>Bring the application to the front when you arrive at your destination</dd>

    <dt>
      <ng-container *ngTemplateOutlet="icon; context: { $implicit: $.permissions.backgroundLocation }" />
      Background Location
    </dt>
    <dd>Needed to provide up to date directions and route updates</dd>

    <dt>
      <ng-container *ngTemplateOutlet="icon; context: { $implicit: $.permissions.location }" />
      Location
    </dt>
    <dd>Needed to provide up to date directions and route updates</dd>

    <dt>
      <ng-container *ngTemplateOutlet="icon; context: { $implicit: $.permissions.fitness }" />
      Motion &amp; Fitness Activity
    </dt>
    <dd>Needed to detect when the vehicle is in motion.</dd>
  </dl>
</ion-content>


<ion-footer>
  <ion-button (click)="dismiss()" type="button" color="primary" expand="block">Ok</ion-button>
</ion-footer>


<!-- Service will return null for the permission if we can't figure out what it is or if an error occurs looking it up -->
<ng-template #icon let-access>
  <ng-container [ngSwitch]="access">
    <ion-icon *ngSwitchCase="true" color="success" name="checkmark-circle-outline"></ion-icon>
    <ion-icon *ngSwitchCase="false" color="danger" name="close-circle-outline"></ion-icon>
    <ion-icon *ngSwitchDefault name="help-circle-outline"></ion-icon>
  </ng-container>
</ng-template>
