import { Component, Input, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-environment-picker',
  templateUrl: './environment-picker.component.html',
  styleUrls: ['./environment-picker.component.scss'],
})
export class EnvironmentPickerComponent {

  public readonly form = new FormGroup({
    environment: new FormControl(),
  });

  @Input() public environments: { url: string, name: string }[];
  @Input() public set selected (url: string) {
    this.form.controls.environment.patchValue(url);
  }

  private readonly modalController = inject(ModalController);

  onSubmit(): void {
    const env = this.environments.find(e => e.url === this.form.value.environment);
    this.modalController.dismiss(env);
  }

  public onCancel(): void {
    this.modalController.dismiss();
  }
}
