export enum NonServiceActionTypeEnum {
  pullOut,
  pullIn,
}

export type NonServiceAction = {
  nonServiceActionType: NonServiceActionTypeEnum
  scheduledStart: Date;
  performed: boolean;
}
