import { InspectionItem } from './inspection';

export enum NonServicePeriodTypeEnum {
  Break,
  PostTrip,
  PreTrip,
}

export enum NonServicePeriodActionEnum {
  End,
  Start,
}

export type NonServicePeriod = {
  hasBeenCompleted?: boolean;
  hasBeenStarted: boolean;
  inspectionIsComplete?: boolean;
  inspectionItems?: InspectionItem[];
  inThePast?: boolean;
  isEnabled?: boolean;
  isLastItem?: boolean;
  nonServicePeriodType: NonServicePeriodTypeEnum
  scheduledEnd: Date;
  scheduledStart: Date;
  timeToStop?: string;
}
