<ion-card *ngIf="job">
  <ion-card-header>
    <ion-card-title>
      {{ jobTypeLabel() }} - {{ job.riderFirstName }} {{ job.riderLastName }}
    </ion-card-title>
    <div class="badges">
      <ion-badge color="medium" class="assistance-badge" *ngFor="let specialAssistance of job.specialAssistances">
        {{ specialAssistance }}
      </ion-badge>
    </div>
  </ion-card-header>
  <ion-card-content *ngIf="job.location.state">
    <address>
      <strong>{{ job.location.name }}</strong> - {{ job.location.address }} {{ job.location.address2 }}
      <br>{{ job.location.city }}, {{job.location.state}}, {{job.location.zipCode}}
      <br>{{ job.jobType == 1 ? job.pickupPhone : job.dropoffPhone }}
    </address>
    <dl>
      <dt>{{ 'LABEL.account' | translate }}:</dt>
      <dd>{{ job.accountCode }}</dd>

      <dt>{{ 'LABEL.auth' | translate }} #:</dt>
      <dd>{{ job.authNumber }}</dd>

      <div *ngIf="showClientId">
        <dt>{{ 'LABEL.riderId' | translate }} #:</dt>
        <dd>{{ job.riderId }}</dd>
      </div>

      <div *ngIf="job.rideComments">
        <dt>{{ 'LABEL.reservationSpecialDir' | translate }}:</dt>
        <dd>{{ job.rideComments }}</dd>
      </div>

      <div *ngIf="job.riderComments">
        <dt>{{ 'LABEL.riderSpecialDir' | translate }}:</dt>
        <dd>{{ job.riderComments }}</dd>
      </div>
    </dl>
  </ion-card-content>
</ion-card>
