import { NoShowReasonEnum, NoShowReasonValue } from './no-show-option';

import { GpsReport } from './gps-report';
import { Location } from './location';

export enum JobType {
  Unknown = 0,
  Pickup = 1,
  DropOff = 2
}

export type IScheduledEvent = {
  inThePast?: boolean;
  timeToStop?: string;
  isLastItem?: boolean;
  isAtNextLocation?: boolean;
}

export type Job = IScheduledEvent & {
  accountCode: string;
  additionalRideFare: number;
  ambulatorySeats: number;
  arriveTime: Date;
  attendantSignatureRequired: boolean;
  authNumber: string;
  companions: number;
  defaultFareTypeId: number;
  driverSignatureRequired: boolean;
  dropoffPhone: string;
  ePassId: string;
  escorts: number;
  estimatedTimeOfArrival?: Date;
  flagStopId: string;
  jobEnabled: boolean;
  jobType: JobType;
  liftRequired: boolean;
  location: Location;
  locationGroupId: string;
  numberOfChildren: number;
  onTheWayTime: Date;
  performTime: Date;
  pickupPhone: string;
  priority: string;
  priorityTime: Date;
  rideComments: string;
  rideId: number;
  rideFare: number;
  riderId?: string;
  riderLastName: string;
  riderComments: string;
  riderFirstName: string;
  riderImageId?: string;
  riderPhone: string;
  riderSignatureRequired: boolean;
  scheduledTime: Date;
  specialAssistances: string[];
  stretcherCount: number;
  supportsDuplicatingNoShow: boolean;
  units: number;
  wheelchairSeats: number;
  willCall: boolean;
  ridePaymentIsSuccessful: boolean | undefined;
}

export function canOnTheWay({ jobType, arriveTime, onTheWayTime, jobEnabled }: Job): boolean {
  if (jobType === JobType.Pickup && !arriveTime && !onTheWayTime) return true;
  if (jobType === JobType.DropOff && jobEnabled && !arriveTime && !onTheWayTime) return true;
  return false;
}

export function canArrive({ jobType, jobEnabled, arriveTime }): boolean {
  if (jobType === JobType.Pickup && !arriveTime) return true;
  if (jobType === JobType.DropOff && jobEnabled && !arriveTime) return true;
  return false;
}

export type JobPerform = {
  rideId: number;
  jobType: JobType;
  fareCollected: number;
  fareTypeId: number;
  numberOfPasses: number;
  numberOfEscorts: number;
  numberOfChildren: number;
  units: number;
  noShowReason: NoShowReasonValue;
  signature: string;
  signatureReason: string;
  gpsReport: GpsReport;
  passId?: string;
  flagStopId: string;
  flagStopPassScannedWhileOffline: boolean;
  additionalFareCollected: number;
}
export function buildJobPerform({ job, signature, signatureReason, noShowReason }
  : { job: Job, signature: string, signatureReason: string, noShowReason?: NoShowReasonEnum })
  : JobPerform {
  return {
    gpsReport: null,
    jobType: job.jobType,
    fareCollected: job.rideFare,
    noShowReason: noShowReason || null,
    numberOfChildren: job.numberOfChildren,
    numberOfEscorts: job.escorts,
    units: job.units,
    numberOfPasses: 1,
    rideId: job.rideId,
    signature: signature,
    signatureReason: signatureReason,
    flagStopId: job.flagStopId,
    flagStopPassScannedWhileOffline: false,
    fareTypeId: job.defaultFareTypeId === null ? null : job.defaultFareTypeId * 1,
    additionalFareCollected: (job.escorts + job.numberOfChildren) * job.additionalRideFare,
  };
}

export type JobArrive = {
  rideId: number;
  flagStopId: string;
  jobType: JobType;
  gpsReport: GpsReport;
}
export function buildJobArrive({ job, gpsReport }: { job: Job, gpsReport: GpsReport }): JobArrive {
  return {
    rideId: job.rideId,
    flagStopId: job.flagStopId,
    gpsReport: gpsReport,
    jobType: job.jobType,
  };
}

export type OnTheWay = {
  rideId: number;
  gpsReport: GpsReport;
  estimatedTimeOfArrival?: Date;
}
export function buildOnTheWay({ job: { rideId }, estimatedTimeOfArrival, gpsReport }: { job: Job, estimatedTimeOfArrival: Date, gpsReport: GpsReport }): OnTheWay {
  return { rideId, estimatedTimeOfArrival, gpsReport };
}
