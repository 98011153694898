import { CanActivateFn, Router } from '@angular/router';

import { AppData } from '../data';
import { inject } from '@angular/core';

export const LoginRequiredGuardService: CanActivateFn = () => {

  const router = inject(Router);
  const { localSettings, routeSummaries, sessionId } = inject(AppData);

  return sessionId && localSettings && routeSummaries ? true : router.createUrlTree(['/login']);
};
