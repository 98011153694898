<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'DISCLAIMER.title' | translate }}:</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" ion-fixed>
  <p>{{ 'DISCLAIMER.termsTitle1' | translate }}:</p>
  <ul>
    <li>{{ 'DISCLAIMER.termsTitle1Option1' | translate }}</li>
    <li>{{ 'DISCLAIMER.termsTitle1Option2' | translate }}</li>
  </ul>
  <p>{{ 'DISCLAIMER.termsTitle2' | translate }}.</p>
  <ul>
    <li>{{ 'DISCLAIMER.termsTitle2Option1' | translate }}</li>
    <li>{{ 'DISCLAIMER.termsTitle2Option2' | translate }}</li>
    <li>{{ 'DISCLAIMER.termsTitle2Option3' | translate }}</li>
  </ul>

  <!--
    Initially remove tab index in order to prevent ionic from scrolling down to the bottom of the page
    Once the page is loaded, we reinstate the tabindex for accessibility
  -->
  <ion-button #acceptButton tabindex="-1" type="button" expand="block" (click)="acceptDisclaimer()" color="primary">
    {{ 'ACTION.accept' | translate }}
  </ion-button>
</ion-content>
