import { SoftwareVersion } from './software-version';

export type Device = {
  identity: DeviceIdentity;
  softwareVersion: SoftwareVersion;
  cordovaVersion: string;
  modelVersion: string;
  platform: string;
  platformVersion: string;
  manufacturer: string;
  serialNumber: string;
  description: string;
}

export type DeviceIdentity = {
  customerName: string;
  uuid: string;
  customerId?: number;
}

export function getDefaultDevice () {
  return {
    cordovaVersion: '0',
    description: null,
    identity: { customerName: null, uuid: null },
    manufacturer: 'None',
    modelVersion: '0',
    platform: 'Simulator',
    platformVersion: '0',
    softwareVersion: { ionicChannel: '0', playStoreVersion: '0', buildVersion: '0.0.0', ionicSnapshotBuildId: '0' },
    serialNumber: '0',
  };
}
