<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="totalPassengersBoardedOrDeboardedAtThisStop <= 0 || scheduledFlagStop">
      <ion-button (click)="dismiss()" size="default" color="secondary" fill="outline">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="scheduledFlagStop?.id">
      <small>{{ 'LABEL.performScheduledStop' | translate }}</small>
      <br>{{ scheduledFlagStop.location }} <ng-container *ngIf="scheduledFlagStop.direction">({{ scheduledFlagStop.direction }})</ng-container>
    </ion-title>
    <ion-title *ngIf="!scheduledFlagStop?.id">{{ 'LABEL.performFlagStop' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card>
    <ion-card-content>
      <div class="passengers-on-board">
        <span class="show-md">{{ passengerDescriptionShort }}:</span>
        <span class="hidden-md">{{ passengerDescription }}:</span>
        <ion-badge color="primary">{{ totalPassengersOnBoard }}</ion-badge>
      </div>

      <div class="odometer">
        <span class="label">{{ 'LABEL.odometer' | translate }}</span>
        <div
          class="increment"
          incrementControl
          [maxValue]="999999"
          [incName]="'LABEL.odometer' | translate"
          [(ngModel)]="odometer"
          [integerOnly]="true"
        >
        </div>
      </div>

      <div class="button-list">
        <div class="pass" *ngIf="paraPassEnabled">
          <ion-button
            color="secondary"
            expand="block"
            (click)="onboardPassClicked(false, false)"
          >
            {{ 'LABEL.onboardPass' | translate }}
          </ion-button>

          <ion-button
            color="secondary"
            fill="outline"
            expand="block"
            (click)="deboardPassClicked()"
            [disabled]="totalPassengersOnBoard == 0"
          >
            {{ 'LABEL.deboardPass' | translate }}
          </ion-button>
        </div>

        <div *ngIf="rapidPassEnabled">
          <ion-button
            color="tertiary"
            (click)="onboardPassClicked(true, false)"
          >
            {{ 'LABEL.rapidOnboardTablet' | translate }}
          </ion-button>
          <ion-button
            color="tertiary"
            fill="outline"
            (click)="onboardPassClicked(true, true)"
          >
            {{ 'LABEL.rapidOnboardBluetooth' | translate }}
          </ion-button>
        </div>
      </div>
    </ion-card-content>
  </ion-card>

  <ng-container *ngFor="let item of riderTypePerforms">
    <ion-card
      *ngIf="
        (totalBoardedAtThisStop(item) || 0) > 0
        || (totalDeboardedAtThisStop(item) || 0) > 0
        || (totalOnboard(item) || 0) > 0
        || item.isActive
      "
    >
      <ion-card-header>
        <ion-card-title>{{ item.riderTypeDescription }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <dl class="on-board-details">
          <div>
            <dt>{{ 'LABEL.currentlyOnboard' | translate }}:</dt>
            <dd><ion-badge color="primary">{{ totalOnboard(item) }}</ion-badge></dd>
          </div>

          <div>
            <dt>{{ 'LABEL.boardedHere' | translate }}:</dt>
            <dd><ion-badge color="secondary">{{ totalBoardedAtThisStop(item) }}</ion-badge></dd>
          </div>

          <div>
            <dt>{{ 'LABEL.deBoardedHere' | translate }}:</dt>
            <dd><ion-badge color="secondary">{{ totalDeboardedAtThisStop(item) }}</ion-badge></dd>
          </div>
        </dl>

        <div class="fare">
          <span class="label">{{ 'LABEL.total' | translate }} {{ paraPassEnabled ? ('LABEL.passless' | translate) : '' }} {{ 'LABEL.fareCollected' | translate }}</span>
          <div
            class="increment"
            incrementControl
            [incName]="'Total ' + (paraPassEnabled?'Passless ':'') + 'Fare Collected'"
            [showOutline]="true"
            [(ngModel)]="item.totalPasslessFareCollected">
          </div>
        </div>

        <div class="button-list">
          <div>
            <ion-button
              type="button"
              (click)="boardClicked(item)"
              [disabled]="!item.isActive"
            >
              {{ 'LABEL.onboard' | translate }} {{ paraPassEnabled ? ('LABEL.passless' | translate) : '' }}
            </ion-button>
            <ion-button
              type="button"
              fill="outline"
              (click)="deboardClicked(item)"
              [disabled]="!totalOnboard(item)"
            >
              {{ 'LABEL.deboard' | translate }} {{ paraPassEnabled ? ('LABEL.passless' | translate): '' }}
            </ion-button>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
  </ng-container>

</ion-content>

<ion-footer>
  <ion-button
    color="primary"
    expand="block"
    (click)="completeFlagStopClicked()"
    [disabled]="false">
    {{ 'LABEL.completeStop' | translate }}
    <ion-icon name="checkmark" slot="end"></ion-icon>
  </ion-button>
</ion-footer>
