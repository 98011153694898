<ion-header>
  <ion-toolbar>
    <ion-title text-wrap>
      {{ 'TITLE.captureSignature' | translate }}
      <ng-container *ngIf="job">for {{ job.riderFirstName }} {{ job.riderLastName }}</ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="signature-content" #signaturePageContent>
  <div class="buttons-wrapper" #buttonsWrapper>
    <ion-button
      color="danger"
      (click)="cancelClicked()"
      type="button"
    >
      {{ 'ACTION.cancel' | translate }}
    </ion-button>
    <ion-button
      class="clear-signature"
      fill="outline"
      color="warning"
      (click)="clearSignatureClicked()"
      [class.hidden]="!isSignatureDrawn"
      [disabled]="!isSignatureDrawn"
      type="button"
    >
      {{ 'ACTION.clearSignature' | translate }}
    </ion-button>
    <ion-button
      color="primary"
      *ngIf="job && job.jobType != JobTypeEnum.DropOff"
      (click)="captureSignatureAtDropoffClicked()"
      >
      <span class="hidden-sig">
        {{ 'ACTION.captureAtDropOff' | translate }}
      </span>
      <span class="show-sig">
        {{ 'LABEL.atDropoff' | translate }}
      </span>
    </ion-button>

    <ion-button
      class="confirm"
      color="success"
      (click)="confirmClicked()"
      [disabled]="!isSignatureDrawn"
    >
      {{ 'ACTION.confirm' | translate }}
    </ion-button>
  </div>
  <div class="signature-wrapper" #signatureWrapper (window:resize)="onResize($event)">
    <div class="signature-prompt noselect" [class.hidden]="didStartSigning">
      {{ 'SIGNATURE.signHere' | translate }}
    </div>
    <signature-pad
      #refBugWorkAround
      [options]="signaturePadOptions"
      (onBeginEvent)="onSignatureDrawStart()"
      (onEndEvent)="onSignatureDrawComplete()"
    ></signature-pad>
  </div>

  <div *ngIf="!job">
    {{ 'SIGNATURE.sigCertify' | translate }}
  </div>
</ion-content>
