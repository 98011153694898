<div class="language-select">
  <ion-item>
    <ion-select
      [attr.aria-label]="'LABEL.language' | translate"
      interface="action-sheet"
      okText="{{'ACTION.okay' | translate }}"
      cancelText="{{'ACTION.dismiss' | translate }}"
      [(ngModel)]="language"
      (ngModelChange)="onChange()"
    >
      <ion-label slot="label">{{ 'LABEL.language' | translate }}</ion-label>
      <ion-select-option value="en">English</ion-select-option>
      <ion-select-option value="es">Español</ion-select-option>
    </ion-select>
  </ion-item>
</div>
