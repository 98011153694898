export enum InspectionResultEnum {
  Pass = 1,
  ServiceDueSoon = 2,
  ServiceCritical = 3
}

export type InspectionItem = {
  inspectionItemId: number;
  questionType: number;
  description: string;
  isCritical: boolean;
  serviceCategory: string;
  result: InspectionResultEnum;
  textResult: string;
}

export type InspectionItemResult = {
  inspectionItemId: number;
  result: string;
}
