import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

import { Drivers, Storage } from "@ionic/storage";

import { Injectable } from "@angular/core";

const STATUS_UNINITIALIZED = 0;
const STATUS_INITIALIZING = 1;
const STATUS_INITIALIZED = 2;

@Injectable({ providedIn: "root" })
/**
 * The storage plugin service abstracts key/value access to the Ionic Storage & Cordova SQLite Storage plugin(s).
 * The initialize function MUST be called before using the plugin.
 */
export class StoragePluginService {

  private _initializeStatus = STATUS_UNINITIALIZED;
  private _storage: Storage | null = null;

  /**
   * Initialize the storage plugin.
   * @returns a void promise.
   */
  public async init(): Promise<void> {
    if (this._initializeStatus === STATUS_UNINITIALIZED) {
      //  Initialize storage plugin
      this._initializeStatus = STATUS_INITIALIZING;
      console.debug("Storage initialization started.");
      this._storage = new Storage({
        driverOrder: [
          CordovaSQLiteDriver._driver,
          Drivers.IndexedDB,
          Drivers.LocalStorage,
        ],
      });
      await this._storage.defineDriver(CordovaSQLiteDriver);
      console.debug("Storage driver defined.");
      await this._storage.create();
      this._initializeStatus = STATUS_INITIALIZED;
      console.debug("Storage created.");
      return Promise.resolve();
    } else if (this._initializeStatus === 1) {
      // the storage plugin is already initializing. wait a second.
      console.debug("Storage not initialized... waiting a second.");
      return await new Promise((resolve, reject) => {
        window.setTimeout(() => {
          console.debug(
            `Storage initialization ${
              this._initializeStatus === STATUS_INITIALIZED ? "complete" : "in progress"
            } after wait`,
          );
          resolve();
        }, 1000);
      });
    }
    return Promise.resolve();
  }

  /**
   * Set a value for a key.
   * @param key The key to store the value with
   * @param value The value to store
   * @returns a promise
   */
  public async set(key: string, value: any): Promise<void> {
    if (!this.isInitialized) await this.init();
    return await (this._storage?.set(key, value) || Promise.resolve());
  }

  /**
   * Get a value from storage by a key
   * @param key The key to fetch a value for.
   * @returns the value that belongs to the key
   */
  public async get(key: string): Promise<any> {
    if (!this.isInitialized) await this.init();
    return await (this._storage?.get(key) || Promise.resolve());
  }

  /**
   * Remove a value from storage by a key
   * @param key The key to find and remove
   * @returns I'm not sure, but the storage api removes "any". (in a promise)
   */
  public async remove(key: string): Promise<void> {
    if (!this.isInitialized) await this.init();
    return await (this._storage?.remove(key) || Promise.resolve());
  }

  /**
   * Get the keys actively used by storage
   * @returns The keys with values currently used in storage (in a promise)
   */
  public async keys(): Promise<string[]> {
    if (!this.isInitialized) await this.init();
    return await (this._storage?.keys() || Promise.resolve([]));
  }

  /**
   * Returns true if the storage api is initialized.
   * @returns True, if initialized (in a promise)
   */
  public async ready(): Promise<boolean> {
    if (!this.isInitialized) await this.init();
    return Promise.resolve(!!this._storage && this._initializeStatus === STATUS_INITIALIZED);
  }

  /**
   * Check to see if a value exists for a given key.
   * @param key The key to look for.
   * @returns True, if the key is used in storage (in a promise)
   */
  public async containsKey(key: string): Promise<boolean> {
    if (!this.isInitialized) await this.init();
    return !!(await this.keys()).find((x) => x === key);
  }

  private get isInitialized() {
    return this._initializeStatus === STATUS_INITIALIZED;
  }
}
