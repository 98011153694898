//Note: do not check in over this. When we run locally, we will be able to identify local testing using this.
export const buildInfo = {
  name: 'com.cts.ParaScope',
  ionicAppId: '6b9ec9dc',
  ionicAppChannel: 'configurable',
  publicVersion: '8.1.3',
  baselineBuildId: 8610226,
  date: '2024-11-14 20:10:42.904Z',
  internalVersion: '24.319.1210',
};
