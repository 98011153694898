<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="backButtonVisible && !this.jobPerform.signature?.length">
      <ion-button fill="outline" color="secondary" (click)="dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'TITLE.jobPerform' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div app-job-card-general [job]="job"></div>

  <ion-card
    *ngIf="job && job.riderSignatureRequired && !jobPerform.noShowReason"
    class="signature">
    <ion-card-header>
      <ion-card-title>{{ 'LABEL.signature' | translate }}</ion-card-title>
      <ion-button fill="outline" (click)="captureSignature()">
        {{ signatureButtonLabel() }}
      </ion-button>
    </ion-card-header>
    <ion-card-content>
      <div *ngIf="job && job.riderSignatureRequired && !jobPerform.signature && !jobPerform.signatureReason">
        {{ 'LABEL.sigPerformDropOff' | translate }}
      </div>
      <img class="signature-image" *ngIf="jobPerform.signature" [src]="jobPerform.signature">
    </ion-card-content>
  </ion-card>
  <ion-card
    class="payment"
    *ngIf="ridePaymentInfo?.hasPayment">
    <ion-card-header>
      <ion-card-title>
        <ng-container *ngIf="ridePaymentInfo.isSuccessful">
          <dt class="success">
            {{'LABEL.'+ridePaymentInfo.type | translate }} {{'LABEL.paymentCollected' | translate}}
          </dt>
        </ng-container>
        <ng-container *ngIf="!ridePaymentInfo.isSuccessful" class="danger">
          <dt class="failed">
            {{'LABEL.'+ridePaymentInfo.type | translate }} {{'LABEL.paymentCollectedFailed' | translate}}
          </dt>
        </ng-container>
      </ion-card-title>
    </ion-card-header>
  </ion-card>
  <ion-card
    class="pass"
    *ngIf="paraPassEnabled && !jobPerform.noShowReason && jobPerform.jobType == JobTypeEnum.Pickup && canCollectFare()">
    <ion-card-header>
      <ion-card-title>
        <ng-container *ngIf="!isUsingPassInSomeRegard">
          {{ 'LABEL.pleaseCollect' | translate }} ${{ job.rideFare | number:'.2-2' }}
        </ng-container>

        <ng-container *ngIf="paraPassEnabled">
          <ng-container *ngIf="isUsingPassInSomeRegard && passHadSufficientFunds">
            {{ 'LABEL.riderFareAccepted' | translate }}
          </ng-container>
          <ng-container
            *ngIf="isUsingPassInSomeRegard
            && !passHadSufficientFunds
            && (!passInformation || (passInformation && !isFlexPass(passInformation?.passType)))">
            {{ 'LABEL.passDebitedFor' | translate }}:
            ${{ (jobPerform.fareCollected <= job.rideFare ? job.rideFare - jobPerform.fareCollected : 0) |
              number: '.2-2' }}
              </ng-container>
              <ng-container *ngIf="isUsingPassInSomeRegard && passInformation && isFlexPass(passInformation?.passType)">
                {{ 'LABEL.passDebited' | translate }}:
                {{ passInformation.debitRideCountForRide }}
                {{ passInformation.debitRideCountForRide === 1 ? ('LABEL.ride' | translate) : ('LABEL.rides' |
                translate) }}
              </ng-container>
          </ng-container>
      </ion-card-title>

      <ion-button
        fill="outline"
        (click)="scanPassClicked()"
        *ngIf="!jobPerform.passId">
        {{ 'ACTION.scanPass'| translate }}
      </ion-button>

      <ion-button
        fill="outline"
        (click)="removePassClicked()"
        *ngIf="jobPerform.passId">
        {{'ACTION.removePass' | translate}}
      </ion-button>
    </ion-card-header>

    <ion-card-content *ngIf="paraPassEnabled && passInformation">
      <dl>
        <ng-container *ngIf="isCashPass(passInformation?.passType)">
          <dt>{{ 'LABEL.rideCost' | translate }}:</dt>
          <dd>${{ passInformation.debitAmoutForRide | number:'.2-2' }}</dd>

          <div class="line"></div>

          <dt>{{ 'LABEL.passBalance' | translate }}:</dt>
          <dd>${{ passInformation.remainingBalance | number:'.2-2' }}</dd>

          <div class="line"></div>

          <dt>{{ 'LABEL.cashCollected' | translate }}:</dt>
          <dd>${{ jobPerform.fareCollected | number:'.2-2' }}</dd>

          <div class="line"></div>

          <dt>{{ 'LABEL.remainBalanceAfterDebit' | translate }}:</dt>
          <dd>${{(passInformation.remainingBalance - passInformation.debitAmoutForRide + jobPerform.fareCollected) |
            number:'.2-2' }}</dd>
        </ng-container>

        <ng-container *ngIf="isFlexPass(passInformation?.passType)">
          <dt>{{ 'LABEL.passEffective' | translate }}:</dt>
          <dd>{{ passInformation.effectiveDate | date:'MM/dd/yyyy' }}</dd>

          <div class="line"></div>

          <dt>{{ 'LABEL.passExpiration' | translate }}:</dt>
          <dd>{{ passInformation.expirationDate | date:'MM/dd/yyyy' }}</dd>

          <div class="line"></div>

          <dt>{{ 'LABEL.currentRidesRemain' | translate }}:</dt>
          <dd>{{ passInformation.remainingRides != null ? passInformation.remainingRides : 'LABEL.unlimited' | translate
            }}</dd>

          <div class="line"></div>

          <dt>{{ 'LABEL.remainRidesAfterDebit' | translate }}:</dt>
          <dd>{{ passInformation.remainingRides != null ? passInformation.remainingRides - 1 : 'LABEL.unlimited' |
            translate }}</dd>
        </ng-container>
      </dl>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>

      <ion-item>
        <div class="control">
          <ion-label>{{ 'LABEL.odometer' | translate }}</ion-label>
          <div
            class="increment"
            incrementControl
            [maxValue]="999999"
            [(ngModel)]="tempOdometer"
            [integerOnly]="true"></div>
        </div>
      </ion-item>

      <ion-item *ngIf="!!jobPerform.noShowReason">
        <ion-label for="noShowOptions">{{ 'LABEL.noShow' | translate }}</ion-label>
        <ion-select [(ngModel)]="jobPerform.noShowReason" id="noShowOptions" interface="popover"
          [interfaceOptions]="{'cssClass': 'wider-popover'}">
          <ion-select-option *ngFor="let option of noShowOptions" [value]="option.id">{{ option.displayValue
            }}</ion-select-option>
        </ion-select>
      </ion-item>

      <ng-container *ngIf="jobPerform.jobType === JobTypeEnum.Pickup && !jobPerform.noShowReason">
        <ng-container *ngIf="showFare">
          <ion-item>
            <ion-label for="fareTypeOptions">{{ 'LABEL.fareType' | translate }}</ion-label>
            <ion-select [(ngModel)]="jobPerform.fareTypeId" id="fareTypeOptions" interface="popover"
              [interfaceOptions]="{'cssClass': 'wider-popover'}">
              <ion-select-option *ngFor="let option of data.fareTypes" [value]="option.id">{{ option.displayValue
                }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <div class="control">
              <ion-label id="additionalPassengerFareLabel">{{ 'LABEL.additionalPassengerFare' | translate }}</ion-label>
              <div
                class="increment"
                incrementControl
                [maxValue]="10000"
                [(ngModel)]="jobPerform.additionalFareCollected"
                labelledBy="additionalPassengerFareLabel"></div>
            </div>
          </ion-item>
        </ng-container>

        <ion-item *ngIf="canCollectFare()">
          <div class="control">
            <ion-label id="cashCollectedLabel">{{ 'LABEL.cashCollected' | translate }}</ion-label>
            <div
              slot="end"
              class="increment"
              incrementControl
              [maxValue]="10000"
              [(ngModel)]="jobPerform.fareCollected"
              labelledBy="cashCollectedLabel"></div>
          </div>
        </ion-item>

        <ion-item>
          <div class="control">
            <ion-label id="numOfEscortsLabel">{{ 'LABEL.numOfEscorts' | translate }}</ion-label>
            <div
              class="increment"
              incrementControl
              [integerOnly]="true"
              [maxValue]="100"
              [minValue]="0"
              [(ngModel)]="jobPerform.numberOfEscorts"
              (ngModelChange)="this.calculateAdditionalFare()"
              labelledBy="numOfEscortsLabel"
              [disabled]="!features.editPassengerCount"></div>
          </div>
        </ion-item>

        <ion-item>
          <div class="control">
            <ion-label id="numOfChildrenLabel">{{ 'LABEL.numOfChildren' | translate }}</ion-label>
            <div
              class="increment"
              incrementControl
              [integerOnly]="true"
              [maxValue]="100"
              [(ngModel)]="jobPerform.numberOfChildren"
              (ngModelChange)="this.calculateAdditionalFare()"
              labelledBy="numOfChildrenLabel"
              [disabled]="!features.editPassengerCount"></div>
          </div>
        </ion-item>

        <ion-item *ngIf="features.showUnits">
          <div class="control">
            <ion-label id="unitesLabel">{{ 'LABEL.units' | translate }}</ion-label>
            <div
              class="increment"
              incrementControl
              [integerOnly]="true"
              [maxValue]="100"
              [(ngModel)]="jobPerform.units"
              labelledBy="unitesLabel"></div>
          </div>
        </ion-item>

      </ng-container>


    </ion-card-content>
  </ion-card>

  <ion-footer>
    <ion-button [disabled]="!clickEnabled" expand="block" color="primary" (click)="confirmClicked()">
      {{ 'LABEL.confirm' | translate }} {{ jobTypeNameLabel() }}
    </ion-button>
  </ion-footer>
</ion-content>
