import { Injectable, inject } from "@angular/core";

import { AppData } from "../data";
import { THEME_KEY } from "../models/theme.type";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ThemeService {

  private readonly data = inject(AppData);

  public handleTheme(): boolean {
    if (!environment.features.darkMode) { return false; }

    const theme = localStorage.getItem('ParaScopeTheme');
    const defaultTheme = environment.features.defaultTheme;

    if (theme) {
      const isDark = theme === 'dark';
      this.toggleDarkTheme(isDark);
      return isDark;
    }

    if (defaultTheme) {
      const isDark = defaultTheme === 'dark';
      this.toggleDarkTheme(isDark);
      return isDark;
    }

    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // Initialize the dark theme based on the initial
    // value of the prefers-color-scheme media query
    const isDark = prefersDark.matches || defaultTheme === 'dark' || theme === 'dark';
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', (mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
    return isDark;
  }

  public toggleDarkTheme(isDark: boolean): void {
    localStorage.setItem(THEME_KEY, isDark ? 'dark' : 'light');
    document.body.classList.toggle('dark', isDark);
    this.data.set('isDark', isDark);
  }

}
