import { AfterViewInit, Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { EMPTY, interval, Observable, Subject } from 'rxjs';
import { IonContent, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { catchError, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { AdjustOdometerPage } from '../adjust-odometer/adjust-odometer.page';
import { AppData } from 'src/app/data';
import { DispatcherService } from '../../services/dispatcher.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { FlagStopPerformPage } from '../flag-stop-perform/flag-stop-perform.page';
import { LocationService } from '../../services/location.service';
import { MaintenancePage } from '../maintenance/maintenance.page';
import { RouteProviderService } from 'src/app/services/route-provider.service';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { SignaturePage } from '../signature/signature.page';
import { TranslateService } from '../../services/translate.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Title } from '@angular/platform-browser';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements AfterViewInit, OnDestroy {

  @ViewChild(IonContent) content: IonContent;

  public readonly data = inject(AppData);
  private readonly destroyed$ = new Subject<boolean>();

  private readonly dispatcherService = inject(DispatcherService);
  private readonly errorHandler = inject(ErrorHandlerService);
  private readonly loadingController = inject(LoadingController);
  private readonly locationService = inject(LocationService);
  private readonly menu = inject(MenuController);
  private readonly modalController = inject(ModalController);
  private readonly router = inject(Router);
  public readonly routeProvider = inject(RouteProviderService);
  private readonly sessionService = inject(SessionService);
  private readonly translationService = inject(TranslateService);
  private readonly utility = inject(UtilityService);

  private readonly gtm = (<any>window).dataLayer || [];

  public readonly clock$ = interval(15000).pipe(
    startWith(() => 0),
    map(() => this.data.localSettings.showClock
      ? DateTime.now().toFormat('HH:mm')
      : '',
    ),
  );

  constructor(public title: Title) {
    const _title = this.translationService.translate('LABEL.route');
    title.setTitle(_title);
  }

  public odometer$: Observable<number | string> = this.data.gpsReport$.pipe(
    map(gpsReport => gpsReport?.odometer || '--'));

  ngAfterViewInit(): void {
    this.utility.scrollTop$.pipe(
      tap(() => this.content.scrollToTop()),
      takeUntil(this.destroyed$),
      catchError(err => { console.error(err); return EMPTY; }),
    ).subscribe();
  }

  async logout(): Promise<void> {
    await this.dispatcherService.logout();
    const _title = this.translationService.translate('ACTION.login');
    this.title.setTitle(_title);
    await this.router.navigateByUrl('/login');
  }

  async dismissModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  async completeRouteClicked(): Promise<void> {
    if (!this.data.route.driverSignatureRequired) {
      await this.completeRoute(null);
      return;
    }
    const modal = await this.modalController.create({
      component: SignaturePage,
      cssClass: 'parascope-full-modal',
      backdropDismiss: false,
    });
    modal.onDidDismiss().then(async result => {
      if (!result?.data?.signature) { return; }
      await this.completeRoute(result.data.signature);
    });
    await modal.present();
  }

  async completeRoute(driverSignature: string): Promise<void> {
    const odometer = await this.modalController.create({
      cssClass: 'parascope-odometer-modal',
      component: AdjustOdometerPage,
      backdropDismiss: false,
    });

    const loading = await this.loadingController.create({
      spinner: 'crescent',
      message: this.translationService.translate("LABEL.waitWhileRouteValidating"),
      backdropDismiss: false,
    });

    await odometer.present();
    await odometer.onDidDismiss();

    await loading.present();

    try {
      const gpsReport = this.data.gpsReport;
      await this.dispatcherService.completeRoute(driverSignature, gpsReport);

      if (this.dispatcherService.pendingUpdatesExist) {
        await this.router.navigateByUrl('/login');
        return;
      }
      const summaries = this.data.routeSummaries;

      if (summaries && summaries.length > 0) {
        await this.sessionService.clearRoute();
        await this.router.navigateByUrl('/route-preview');
      } else {
        await this.logout();
      }
    } catch (e) {
      // if there was an error the pending update is queued,
      // it will get there eventually so take them back to the login page
      this.errorHandler.handleError(e);
      await this.router.navigateByUrl('/login');

    } finally {
      await loading.dismiss();
    }

    await odometer.present();
  }

  async openMaintenanceModal() {
    const dialog = await this.modalController.create({
      component: MaintenancePage,
      backdropDismiss: false,
    });
    await dialog.present();
  }

  async adjustOdometerClicked() {
    const previewModal = await this.modalController.create({ cssClass: 'parascope-odometer-modal', component: AdjustOdometerPage, backdropDismiss: false });
    await previewModal.present();
  }

  navigateHomeClicked() {
    if (!this.data.route.endDepoLocation.coordinate) return;
    this.locationService.navigateToDestination(this.data.route.endDepoLocation.coordinate);
  }

  async performFlagStopClicked() {
    const modal = await this.modalController.create({
      component: FlagStopPerformPage,
      cssClass: 'parascope-full-modal',
      backdropDismiss: false,
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  async toggleMenu() {
    await this.menu.toggle('main-menu');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
