import { AsyncSubject, Subscription } from 'rxjs';
import { BackgroundModeEventType, GpsLocationModeEnum, IEnvironmentInformation, NativePluginService, NetworkTypeEnum } from './plugin/native-plugins.service';

import { AppData } from '../data';
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus } from '@capacitor/network';
import { Device } from '../models/device';
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { TranslateService } from './translate.service';

export enum SignalDisplayStrength {
  None,
  OneBar,
  TwoBars,
  ThreeBars,
  FourBars,
  Full
}

@Injectable({ providedIn: 'root' })
export class DeviceService {

  private _bgModeSubscriptions: Subscription[] = [];
  private _bgModeDocumentHandlersAttached = false;
  private _envInfo: (IEnvironmentInformation | null) = null;

  packageName = '';

  public readonly initialized$ = new AsyncSubject<boolean>();

  readonly SignalStrengthStrings = {
    0: 'Disconnected',
    1: 'Very Weak',
    2: 'Weak',
    3: 'OK',
    4: 'Good',
    5: 'Great',
  };

  constructor(
    private platform: Platform,
    private pluginService: NativePluginService,
    private translateService: TranslateService,
    private data: AppData,
  ) {
    Network.getStatus().then(status => this.data.set('connectionStatus', status));
  }

  /** Gets called from the app module. Do NOT call it from anywhere else */
  public async init() {
    const { customerId, customerName, uuid, description } = this.data.localSettings || { };
    const {
      manufacturer,
      model: modelVersion,
      operatingSystem: platform,
      operatingSystemVersion: platformVersion,
     }: IEnvironmentInformation = await this.pluginService.getEnvironmentInfo(true) || { };

    const device = this.updateDeviceInformation({
      identity: { customerName, customerId, uuid },
      manufacturer: manufacturer || 'unknown',
      modelVersion, platform, platformVersion, description,
      cordovaVersion: 'N/A',
      serialNumber: 'N/A',
    });
    console.info('device info', device);

    this.initialized$.next(true);
    this.initialized$.complete();
  }

  public networkStatusChanged(status: ConnectionStatus) {
    console.debug('--Network status changed--', status);
    this.data.set('connectionStatus', status);
  }

  /**
   * Returns true if this is an outdated device.
   * For now that is defined by being pre v6.
   * We can refine that more later.
   */
  get isPOSAndroidTab(): boolean {
    return this.isNativeDevice && !this.isIos && this.data.device
    ? parseFloat(this.data.device.platformVersion) < 6
    : false;
  }

  get isNativeDevice(): boolean {
    return Capacitor.isNativePlatform();
  }

  get isIos(): boolean {
    return this.platform.is('ios');
  }

  get isAndroid(): boolean {
    return this.platform.is('android');
  }

  updateDeviceInformation(updates: Partial<Device>): Device {
    const updated: Device = { ...this.data.device, ...updates };
    this.data.set('device', updated);
    return updated;
  }

  /**
   * Enables background mode (run and stay awake in the background)
   */
  public async setBackgroundMode() {
    await this.pluginService.enableBackgroundMode('ParaScope', 'icon', this.translateService.translate('LABEL.runningInBackground'));
    console.debug("Enabled Background Mode");

    // Doing this just like we did before (in the pre-capacitor application), and in the same order!
    // In that application, we disable webview optimizations after enabling background mode, but also on the activate event.
    this.disableOptimizations('setBackgroundMode');

    // subscribe to the activate event and disable webview optimizations, like we did before, but also do it on other events, including browser media player events.
    // SEE: https://github.com/katzer/cordova-plugin-background-mode/issues/562#issuecomment-939964545
    if (this._bgModeSubscriptions.length > 0) { this._bgModeSubscriptions.forEach(x => x.unsubscribe()); }
    this._bgModeSubscriptions = [];

    this._bgModeSubscriptions.push(
      this.pluginService.subscribeToBackgroundModeEvent(BackgroundModeEventType.Activate)
        .subscribe(() => this.disableOptimizations('backgroundMode.activate')),
    );

    if (this.isAndroid7x) {
      this._bgModeSubscriptions.push(
        this.pluginService.subscribeToBackgroundModeEvent(BackgroundModeEventType.Deactivate)
          .subscribe(() => this.disableOptimizations('backgroundMode.deactivate')),
      );
    }

    this._bgModeSubscriptions.push(
      this.pluginService.subscribeToBackgroundModeEvent(BackgroundModeEventType.Failure)
        .subscribe(err => {
          console.warn("Background mode failure event fired.", err);
          if (this.isAndroid7x) this.disableOptimizations('backgroundMode.failure');
        }),
    );

    if (this.isAndroid7x && !this._bgModeDocumentHandlersAttached) {
      this._bgModeDocumentHandlersAttached = true;

      document.addEventListener("pause", () => {
        if (this._bgModeSubscriptions.length > 0) this.disableOptimizations('document.pause');
      }, false);

      document.addEventListener("resume", () => {
        if (this._bgModeSubscriptions.length > 0) this.disableOptimizations('document.resume');
      }, false);
    }
  }

  private get isAndroid7x() {
    return this._envInfo && this._envInfo.platform === "android" && this._envInfo.operatingSystemVersion.startsWith('7.');
  }

  /**
   * This will disable webview optimizations (using the backgroundMode plugin). This is supposed to keep the webview (browser control) awake, even when running in the background.
   * @param evtName Some context to where this is called, like an event name if being called in a handler.
   */
  private disableOptimizations(evtName: string) {
    try {
      this.pluginService.disableBackgroundModeWebViewOptimizations().then((v) => { });
      console.debug(`Called disableWebViewOptimizations in BackgroundMode (${evtName})`);
    } catch (error) { console.error(`Error calling disableWebViewOptimizations (${evtName})`, error); }

    // If the device is a 7.x device (api versions 24 and 25), disable the battery optimizations, too.
    if (this.isAndroid7x) {
      try {
        this.pluginService.disableBackgroundModeBatteryOptimizations().then((v) => { });
        console.debug(`Called disableBatteryOptimizations in BackgroundMode (${evtName})`);
      } catch (error) { console.error(`Error calling disableBatteryOptimizations (${evtName})`, error); }
    }
  }

  async getDeviceInfoArray(): Promise<any[]> {
    const arr = [];
    // this is the hard way, but wanted pretty names and nested objects
    if (this.data.device) {
      arr.push(
        { name: 'Spacer', value: 'Device Details' },
        { name: 'Cordova Version', value: this.data.device.cordovaVersion },
        { name: 'Manufacturer', value: this.data.device.manufacturer },
        { name: 'Model', value: this.data.device.modelVersion },
        { name: 'Platform', value: this.data.device.platform },
        { name: 'Platform Version', value: this.data.device.platformVersion },
        { name: 'Serial Number', value: this.data.device.serialNumber },
        { name: 'Description', value: this.data.device.description },
        { name: 'Customer', value: this.data.device.identity.customerName },
        { name: 'Uuid', value: this.data.device.identity.uuid },
      );
    }

    if (this.isNativeDevice) {
      const devStatus = await this.pluginService.getDeviceStatus();
      const sensors = await BackgroundGeolocation.getSensors();


      try {
        arr.push(
          { name: 'Spacer', value: 'Platform Details' },
          { name: 'Landscape', value: this.platform.isLandscape() },
          { name: 'Size', value: `${this.platform.width()} X ${this.platform.height()}` },
          { name: 'Android', value: this.platform.is('android') },
        );
      } catch (e) {
        console.warn('Error showing platform info', e);
      }

      arr.push(
        { name: 'Spacer', value: 'Native Device Details' },
        { name: 'Background Mode Enable', value: devStatus?.isBackgroundModeEnabled },
        { name: 'Background Mode Active', value: devStatus?.isBackgroundModeActive },

        { name: 'Camera Available', value: devStatus?.isCameraAvailable },
        { name: 'Camera Present', value: devStatus?.isCameraPresent },

        { name: 'Gyroscope Available', value: !!sensors.gyroscope },
        { name: 'Accelerometer Available', value: !!sensors.accelerometer },
        { name: 'Motion Hardware Available', value: !!sensors.motion_hardware },
        { name: 'Magnetometer Available', value: !!sensors.magnetometer },

        { name: 'WiFi Enabled', value: devStatus?.isWifiEnabled },
        { name: 'WiFi Available', value: devStatus?.isWifiAvailable },
        { name: 'Network Connected', value: devStatus?.isNetworkConnected },
        { name: 'Network Type', value: NetworkTypeEnum[devStatus.networkConnectionType] },

        { name: 'Disk Free', value: `${devStatus?.diskFree} of ${devStatus?.diskTotal}` },
        { name: 'Memory Used', value: devStatus?.memoryUsed },

        { name: 'Google Maps Support', value: await this.pluginService.isGoogleMapsAvailable() },
        { name: 'GPS Location Mode', value: GpsLocationModeEnum[devStatus.gpsLocationMode] },
        { name: 'GPS Location Enabled', value: devStatus?.isGpsLocationEnabled },
        { name: 'GPS Location Available', value: devStatus?.isGpsLocationAvailable },
      );

      try {
        const state = await BackgroundGeolocation.getState();
        arr.push(
        { name: 'Spacer', value: 'BBGO Details' },
         { name: 'BgGeo Enabled', value: state.enabled },
         { name: 'BgGeo Odometer', value: state.odometer },
         { name: 'BgGeo Scheduler Enabled', value: state.schedulerEnabled },
         { name: 'BgGeo Tracking Mode', value: state.trackingMode === 1 ? 'Location & Geofence' : 'Geofence only' },
        );
        const provState = await BackgroundGeolocation.getProviderState();
        arr.push(
          { name: 'BgGeo Provider Enabled', value: provState.enabled },
          { name: 'BgGeo Provider GPS', value: provState.gps },
          { name: 'BgGeo Provider Network', value: provState.network },
          { name: 'BgGeo Provider Status', value: provState.status },
          { name: 'BgGeo Power Saver Mode', value: await BackgroundGeolocation.isPowerSaveMode() },
        );
      } catch (e) {
        console.warn('Error showing BackgroundGeolocation info', e);
      }

    }
    return arr;
  }

}
