import { CanActivateFn, Router } from '@angular/router';

import { AppData } from '../data';
import { inject } from '@angular/core';

export const RouteRequiredGuardService: CanActivateFn = () => {

  const router = inject(Router);
  const data = inject(AppData);
  const { localSettings, route, sessionId } = data;

  return sessionId && localSettings && route ? true : router.createUrlTree(['/login']);
};
