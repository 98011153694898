<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="secondary" fill="outline" (click)="dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>

    </ion-buttons>
    <ion-title>GPS Info v5.0</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-row>
    <ion-col>
      <ion-button fill="solid" expand="block" (click)="changePace()">
        Move
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <pre><code>{{ gpsReport | async | json }}</code></pre>
    </ion-col>
  </ion-row>
</ion-content>
