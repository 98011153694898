import { GpsReport } from './gps-report';
import { Job } from './job';

export type CallRequest = {
  rideId: number
  gpsReport: GpsReport
}
export function buildCallRequest({ job: { rideId }, gpsReport }: { job: Job, gpsReport: GpsReport}): CallRequest {
  return { rideId, gpsReport: gpsReport };
}
