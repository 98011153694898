import { Component, inject } from "@angular/core";
import { Job, JobType } from "src/app/models/job";

import { JobDetailsPage } from "src/app/pages/job-details/job-details.page";
import { ModalController } from "@ionic/angular";
import { ScheduledEvent } from "../../job-list/job-list.component";
import { UtilityService } from "src/app/services/utility.service";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: './group-details.dialog.html',
  styleUrls: [ './group-details.dialog.scss' ],
})
export class GroupDetailsDialog {
  showClientId: boolean = environment.features.showClientId;

  private readonly modalController = inject(ModalController);
  private readonly utility = inject(UtilityService);

  public readonly jobTypes = JobType;
  private set jobs (jobs: ScheduledEvent<Job>[]) {
    this.pickups = jobs.filter(j => j.jobType === JobType.Pickup);
    this.dropOffs = jobs.filter(j => j.jobType === JobType.DropOff);
  }
  public pickups: ScheduledEvent<Job>[] = [];
  public dropOffs: ScheduledEvent<Job>[] = [];

  public async showJobDetails(job: Job) {
    const detailModal = await this.modalController.create({
      component: JobDetailsPage,
      backdropDismiss: false,
      componentProps: { job, isDetailView: true },
    });
    await detailModal.present();
    const { data: { rideId, jobPerformed }} = await detailModal.onDidDismiss() || { data: { }};
    if (jobPerformed && rideId) { this.syncView(job); }
  }

  private syncView(job: Job) {
    if (job.jobType === this.jobTypes.Pickup) { this.pickups = this.pickups.filter(j => j.rideId !== job.rideId); }
    if (job.jobType === this.jobTypes.DropOff) { this.dropOffs = this.dropOffs.filter(j => j.rideId !== job.rideId); }
    if (!this.pickups.length && !this.dropOffs.length) { this.modalController.dismiss(); }
  }

  public handleName(first: string, last: string): string {
    return this.utility.handleName(first, last);
  }

  public onCancel(): void {
    this.modalController.dismiss();
  }

}
