import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';

import { AppData } from 'src/app/data';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PredefinedMessage } from 'src/app/models/predefined-message';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent {

  private readonly modalController = inject(ModalController);
  private readonly loadingController = inject(LoadingController);
  private readonly messageService = inject(MessageService);
  private readonly notificationService = inject(NotificationService);
  private readonly translateService = inject(TranslateService);
  private readonly data = inject(AppData);

  public readonly messages: PredefinedMessage[] = this.data.predefinedMessages;
  public readonly form = new FormGroup({
    message: new FormControl<number>(null, [ Validators.required ]),
  });
  public submitting = false;

  public async onSubmit(): Promise<void> {
    const loader = await this.handleLoader();
    try {
      this.submitting = true;
      await this.messageService.sendMessage(this.form.value.message);
      this.notificationService.success(this.translateService.translate('LABEL.messageSent'));
      await this.close();
    } catch (error) {
      console.error(error);
      this.notificationService.warning(this.translateService.translate('LABEL.messageNotSent'));
    } finally {
      this.submitting = false;
      if (loader) { loader.dismiss(); }
    }
  }

  public async close(): Promise<void> {
    await this.modalController.dismiss();
  }

  private async handleLoader(): Promise<HTMLIonLoadingElement> {
    try {
      return await this.loadingController.create({
        spinner: 'crescent',
        message: this.translateService.translate('ACTION.sending') + '...',
      });
    } catch (err) {
      return null;
    }
  }

}
