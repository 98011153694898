import { BehaviorSubject, Observable, Subject, firstValueFrom, map, switchMap } from 'rxjs';

import { AppData } from '../data';
import { DeviceService } from './device.service';
import { FairmaticCall } from '../models/fairmatics';
import { FairmaticStatusChange } from '../models/fairmatic-status-change';
import { Injectable } from '@angular/core';
import { ZendrivePluginService } from './plugin/zendrive-plugin.service';
import { ZendriveStatus } from './plugin/zendrive-plugin.service';

@Injectable({
  providedIn: 'root',
})
export class FairmaticService {

  private customerList = [
    'ASSISTSERVICESEST',
    'ASSISTSERVICESCST',
    'ASSISTSERVICESMST',
    'ASSISTSERVICESPST',
    'QA_DEREKDEMO',
    'QA_CTS',
    'DEV_DEREKDEMO',
    'DEREKDEMO',
    'PSCOPE',
  ];
  public fairmaticApplicationKey: string | null = null;

  public fairmaticDriverId: string | null = null;
  public readonly fairmaticDriverId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');

  public callHistory: FairmaticCall[] = [];
  public readonly callHistory$ = new BehaviorSubject<FairmaticCall[]>(this.callHistory);

  private statusChangeEvent: FairmaticStatusChange | null = null;
  public readonly status$: BehaviorSubject<ZendriveStatus | null> = new BehaviorSubject<ZendriveStatus>(null);
  public readonly onStatusChangeEvent: Subject<FairmaticStatusChange> = new Subject<FairmaticStatusChange>();

  public readonly isZendriveSetup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isValidDevice$: Observable<boolean> = this.deviceService.initialized$.pipe(
    switchMap(() => this.data.device$),
    map(device => this.deviceService.isNativeDevice),
  );
  public isValidCustomer$: Observable<boolean> = this.deviceService.initialized$.pipe(
    switchMap(() => this.data.device$),
    map(device => {
      if (this.fairmaticApplicationKey) { return true; }
      const { customerName } = device?.identity || {};
      return customerName ? !!this.customerList.includes(this.customerNameUppercase(customerName)) : false;
    }),
  );

  constructor(
    private data: AppData,
    private zendriveService: ZendrivePluginService,
    private deviceService: DeviceService,
  ) { }

  /**
   * Checks that the user is on a mobile device and that
   * the customer is Fairmatic.
   * @returns promise boolean
   */
  async isFairmatic(): Promise<boolean> {
    await firstValueFrom(this.deviceService.initialized$);
    const customerName = this.data.device?.identity.customerName;
    if (!customerName) { return false; }
    const validDevice = this.deviceService.isNativeDevice;
    const validCustomer = this.customerList.includes(this.customerNameUppercase(customerName));
    return validDevice && validCustomer;
  }

  async isValidCustomer(): Promise<boolean> {
    if (this.fairmaticApplicationKey) { return true; }
    await firstValueFrom(this.deviceService.initialized$);
    const customerName = this.data.device.identity.customerName;
    if (!customerName) { return false; }
    return this.customerList.includes(this.customerNameUppercase(customerName));
  }

  private getCustomerAPIKey(customerName: string): string {
    if (this.fairmaticApplicationKey) { return this.fairmaticApplicationKey; }
    const cName = this.customerNameUppercase(customerName);
    switch (cName) {
      case 'AssistServicesPST'.toUpperCase():
      case 'AssistServicesCST'.toUpperCase():
      case 'AssistServicesEST'.toUpperCase():
      case 'AssistServicesMST'.toUpperCase():
        return 'qIT2nWj1hLNwoa4AcnfQrKNOJ8fS0xz3';
      case 'QA_DerekDemo'.toUpperCase():
      case 'QA_CTS'.toUpperCase():
      case 'DerekDemo'.toUpperCase():
      case 'PScope'.toUpperCase():
        // return '9EyQCdI5CvcVSlgI9RbZmcMi972o0vsU';
        return 'qIT2nWj1hLNwoa4AcnfQrKNOJ8fS0xz3';
      default: return null;
    }
  }
  async setupDriver(): Promise<void> {
    let _driverId = '';
    try {
      if (!(await this.isFairmatic())) {
        this.addHistory(`[setup]: is not fairmatic`);
        return;
      }
      const route = this.data.route;
      await firstValueFrom(this.deviceService.initialized$);
      const { customerName, customerId } = this.data.device.identity || {};
      const cName = this.customerNameUppercase(customerName);
      _driverId = `${cName}-${customerId}-Unknown-Driver-0`;
      if (route?.driverId) {
        const { driverLastName, driverFirstName, driverId } = route;
        _driverId = `${cName}-${customerId}-${driverLastName}-${driverFirstName}-${driverId}`;
      }
      this.fairmaticDriverId = this.zendriveService.cleanDriverId(_driverId);
      this.fairmaticDriverId$.next(this.fairmaticDriverId);
      const customerKey = this.getCustomerAPIKey(customerName);
      if (!customerKey) {
        this.addHistory(`Failure [setup] no customerKey`);
        return;
      }
      await this.zendriveService.setup(customerKey, this.fairmaticDriverId);
      this.addHistory(`SUCCESS [setup] driver=${_driverId}, customer=${customerKey}`);
      const status = await this.zendriveService.getActiveDriveInfo().catch(err => ({} as ZendriveStatus));
      this.status$.next(status);
      this.isZendriveSetup$.next(true);
      this.zendriveAction('goOnDuty');
    } catch (error) {
      this.isZendriveSetup$.next(false);
      console.error('[Zendrive]: Failed to call setup', error);
      this.addHistory(`ERROR [setup] for ${_driverId} - ${error}`);
    }
  }

  async getActiveDriveInfo(): Promise<ZendriveStatus | null> {
    if (!(await this.isFairmatic())) { return null; }
    try {
      const activeDriveInfo = await this.zendriveService.getActiveDriveInfo();
      this.addHistory('SUCCESS [getActiveDriveInfo]');
      return activeDriveInfo;
    } catch (error) {
      console.error(error);
      this.addHistory(`ERROR [getActiveDriveInfo] ${error}`);
      return null;
    }
  }

  public async zendriveAction(action: 'onTheWay' | 'goOnDuty' | 'pickupPassenger' | 'dropOffPassenger' | 'goOffDuty'): Promise<void> {
    if (!(await this.isFairmatic())) { return; }
    const time = new Date();
    if (!(await firstValueFrom(this.isZendriveSetup$))) {
      this.addHistory(`Error [${action} - Zendrive not setup]`, time);
      return;
    }
    try {
      const previousStatus = await this.zendriveService.getActiveDriveInfo().catch(err => ({} as ZendriveStatus));
      switch (action) {
        case 'dropOffPassenger':
        case 'goOnDuty': await this.zendriveService.goOnDuty(); break;

        case 'pickupPassenger': await this.zendriveService.pickupPassenger(); break;
        case 'onTheWay': await this.zendriveService.acceptPassengerRequest(); break;
        case 'goOffDuty': await this.zendriveService.goOffDuty(); break;
      }
      this.addHistory(`SUCCESS [${action}]`, time);
      const newStatus = await this.zendriveService.getActiveDriveInfo().catch(err => ({} as ZendriveStatus));
      this.status$.next(newStatus);
      const event: FairmaticStatusChange = this.statusChangeEvent || {
        driverId: this.fairmaticDriverId,
        period: newStatus.insurancePeriod,
        start: time,
      };
      if (previousStatus.insurancePeriod && previousStatus.insurancePeriod !== newStatus.insurancePeriod) {
        event.end = time;
      }
      this.onStatusChangeEvent.next(event);
      this.statusChangeEvent = newStatus.insurancePeriod > 0
        ? {
          driverId: this.fairmaticDriverId,
          period: newStatus.insurancePeriod,
          start: time,
        } : null;
    } catch (error) {
      console.error(`[Zendrive]: ERROR in ${action}`, error);
      this.addHistory(`Error [${action}]: ${error.message}`, time);
    }
  }

  clear(): void {
    this.fairmaticApplicationKey = '';
    this.fairmaticDriverId = '';
    this.fairmaticDriverId$.next(this.fairmaticDriverId);
    this.isZendriveSetup$.next(false);
  }

  private customerNameUppercase(customerName: string = this.data.device?.identity?.customerName): string {
    if (!customerName) { return ''; }
    return customerName.trim().toUpperCase();
  }

  private addHistory(message: string, time: Date = new Date()): void {
    this.callHistory.push({ time, message });
    this.callHistory$.next(this.callHistory);
  }

}
