import { BarcodeScannerResult, MapTypeEnum } from "./native-plugins.service";
import { Observable, fromEvent } from "rxjs";

import { Injectable } from "@angular/core";
import { UAParser } from "ua-parser-js";

@Injectable({
  providedIn: "root",
})
/**
 * A service that contains web implementations of some plugin functionality like geolocation, os & device info, etc.
 */
export class WebPluginFunctionalityService {
  private watchId: any;
  constructor() {}

  /**
   * Is the browser online?
   * @returns {boolean} returns true if the navigator says you're online
   */
  public get isBrowserOnline(): boolean {
    return window?.navigator?.onLine;
  }

  /**
   * Checks to see if you can  get the current position using the navigator geolocation api.
   * @returns true if you can get coordinates for the current location. (in a promise)
   */
  public isGpsLocationAvailable(): Promise<boolean> {
    if (navigator.geolocation) {
      return new Promise<boolean>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (location) => {
            resolve(!!location?.coords);
          },
          (err) => {
            reject(false);
          },
        );
      });
    } else {
      return Promise.reject(false);
    }
  }

  /**
   * Try to get the current geolocation from the browser.
   * @returns a geoposition (lat/lng)
   */
  public getCurrentLocation(): Promise<GeolocationPosition> {
    if (navigator.geolocation) {
      return new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (location) => {
            resolve(location);
          },
          (err) => {
            reject(err);
          },
        );
      });
    } else {
      return Promise.reject('Geolocation not enabled');
    }
  }

  /**
   * Assign a listener to execute when the browser records a new geolocation.
   * @param handler a handler to execute when the browser records a new geolocation.
   */
  public onGeoLocationChanged(handler: (position: GeolocationPosition) => void): void {
    if (navigator.geolocation) {
      this.watchId = navigator.geolocation.watchPosition(handler, null, { enableHighAccuracy: true });
    }
  }

  /**
   * Remove the current geolocation handler (if one has been attached)
   */
  public removeGeoLocationHandler(): void {
    if (navigator.geolocation && !!this.watchId) {
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = undefined;
    }
  }

  /**
   * Launches google maps in another tab/window
   * @param latitude {number} Longitude
   * @param longitude {number} Latitude
   * @param mapType {MapTypeEnum} "geo" or "turn-by-turn" for directions
   * @returns {Promise<any>}
   */
  public async navigateToMaps(
    latitude: number,
    longitude: number,
    mapType: MapTypeEnum,
  ): Promise<any> {
    if (navigator.geolocation) {
      const urlPrefix: string = "https://www.google.com/maps/";
      const result = await new Promise<string>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (location) => {
            if (mapType === MapTypeEnum.TurnByTurnDirections) {
              window.open(
                `${urlPrefix}dir/?api=1&dir_action=navigate&travelmode=driving&origin=${location.coords.latitude}%2C${location.coords.longitude}&destination=${latitude}%2C${longitude}`,
                "parascope-navigate",
              );
            } else {
              window.open(
                `${urlPrefix}search/?api=1&query=${latitude}%2C${longitude}`,
                "parascope-navigate",
              );
            }
            resolve("OK");
          },
          (err) => {
            reject(`ERROR: ${err}`);
          },
        );
      });

      return result;
    } else {
      return `NOT SUPPORTED`;
    }
  }

  /**
   * Checks if camera hardware is present on device.
   * @returns {Promise<any>}
   */
  public isCameraPresent(): Promise<boolean> {
    const md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return Promise.resolve(false);
    else {
      return new Promise<boolean>((resolve, reject) => {
        md.enumerateDevices().then(
          (devices) => {
            resolve(devices.some((device) => "videoinput" === device.kind));
          },
          (err) => {
            reject(false);
          },
        );
      });
    }
  }

  /**
   * Get notified when the device goes online
   * @returns {Observable<any>} Returns an observable.
   */
  public onNetworkOnline(): Observable<any> {
    return fromEvent(window, "online");
  }

  /**
   * Get notified when the device goes offline
   * @returns {Observable<any>} Returns an observable.
   */
  public onNetworkOffline(): Observable<any> {
    return fromEvent(window, "offline");
  }

  /**
   * Downlink Max Speed
   * @return {string}
   */
  public networkDownlinkMax(): string {
    const nav = window.navigator as any;
    if (nav?.connection && nav?.connection?.downlinkMax) {
      return nav.connection.downlinkMax;
    } else return "NOT SUPPORTED";
  }

  /**
   * Try to get the device vendor from the browser User Agent
   */
  public get deviceVendor(): string {
    return UAParser().device.vendor || "unknown";
  }

  /**
   * Try to get the browser name from the browser User Agent
   */
  public get deviceBrowserName(): string {
    return UAParser().browser.name || "unknown";
  }

  /**
   * Try to get the OS version from the browser User Agent
   */
  public get deviceOperatingSystemVersion(): string {
    return `${UAParser().os.name} ${UAParser().os.version}`;
  }

  /**
   * Try to get the OS name from the browser User Agent
   */
  public get deviceOperatingSystemName(): string {
    return UAParser().browser.version || "unknown";
  }

  /**
   * A function that replaces the "scan a barcode" feature of a plugin by just prompting for the barcode value.
   * @param promptText The text to prompt the user with.
   * @returns a BarcodeScannerResult (cancelled, format, and text)
   */
  public simulateBarcodeScanner(promptText?: string): BarcodeScannerResult {
    const value = prompt(promptText || "Please Enter the barcode value");
    return {
      cancelled: !value,
      format: "QR_CODE",
      text: value,
    } as BarcodeScannerResult;
  }

}
