import { Component, Input, OnInit, inject } from '@angular/core';
import { NonServiceAction, NonServiceActionTypeEnum } from '../../models/non-service-action';

import { AppData } from 'src/app/data';
import { DispatcherService } from '../../services/dispatcher.service';
import { TranslateService } from '../../services/translate.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-non-service-action',
  templateUrl: './non-service-action.component.html',
  styleUrls: ['./non-service-action.component.scss'],
})
export class NonServiceActionComponent implements OnInit {

  public readonly data = inject(AppData);
  public readonly dispatcherService = inject(DispatcherService);
  private readonly translateService = inject(TranslateService);
  private readonly notify = inject(NotificationService);

  @Input() nonServiceAction: NonServiceAction;
  typeString = '';

  ngOnInit() {
    switch (this.nonServiceAction.nonServiceActionType) {
      case NonServiceActionTypeEnum.pullOut:
        this.typeString = this.translateService.translate('LABEL.pullOut');
        break;
      case NonServiceActionTypeEnum.pullIn:
        this.typeString = this.translateService.translate('LABEL.pullIn');
        break;
      default:
        console.error('Missing or invalid action type');
        this.notify.error(this.translateService.translate('MESSAGE.missingActionType'));
        break;
    }
  }

  actionClicked() {
    // in case we decide to add more actions
    switch (this.nonServiceAction.nonServiceActionType) {
      case NonServiceActionTypeEnum.pullOut:
        this.dispatcherService.pullOut();
        break;
      case NonServiceActionTypeEnum.pullIn:
        this.dispatcherService.pullIn();
        break;
      default:
        console.error('Missing or invalid action type');
        this.notify.error(this.translateService.translate('MESSAGE.missingActionType'));
        break;
    }
  }
}
