import {Inject, Injectable, Injector } from '@angular/core';
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {TranslateService} from '../services/translate.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(@Inject(Injector) private readonly injector: Injector,
                private translateService: TranslateService) {
    }

    private get toastr() {
        return this.injector.get(ToastrService);
    }

    success(message: string, toastLife: number = 3000, clickToDismiss?: boolean) {
        clickToDismiss = (clickToDismiss === null || clickToDismiss === undefined) ? true : clickToDismiss;
        if (toastLife) {
            this.toastr.success(message, this.translateService.translate('TITLE.success'),
                {tapToDismiss: clickToDismiss, timeOut: toastLife, enableHtml: true, onActivateTick: true});
        } else {
            this.toastr.success(message, this.translateService.translate('TITLE.success'),
                {tapToDismiss: clickToDismiss, disableTimeOut: true, enableHtml: true, onActivateTick: true});
        }
    }

    warning(message: string, toastLife: number = 3000, clickToDismiss?: boolean) {
        clickToDismiss = (clickToDismiss === null || clickToDismiss === undefined) ? true : clickToDismiss;
        if (toastLife) {
            this.toastr.warning(message, this.translateService.translate('TITLE.warning'),
                {enableHtml: true, tapToDismiss: clickToDismiss, timeOut: toastLife, onActivateTick: true});
        } else {
            this.toastr.warning(message, this.translateService.translate('TITLE.warning'),
                {enableHtml: true, tapToDismiss: clickToDismiss, disableTimeOut: true, onActivateTick: true});
        }
    }

    error(message: string, toastLife: number = 3000, clickToDismiss?: boolean) {
        clickToDismiss = (clickToDismiss === null || clickToDismiss === undefined) ? true : clickToDismiss;
        if (toastLife) {
            this.toastr.error(message, this.translateService.translate('TITLE.error'),
                {tapToDismiss: clickToDismiss, timeOut: toastLife, enableHtml: true, onActivateTick: true});
        } else {
            this.toastr.error(message, this.translateService.translate('TITLE.error'),
                {tapToDismiss: clickToDismiss, disableTimeOut: true, enableHtml: true, onActivateTick: true});
        }
    }

    info(message: string, toastLife: number = 3000, clickToDismiss?: boolean) {
        clickToDismiss = (clickToDismiss === null || clickToDismiss === undefined) ? true : clickToDismiss;
        if (toastLife) {
            this.toastr.info(message, this.translateService.translate('TITLE.info'),
                {tapToDismiss: clickToDismiss, timeOut: toastLife, enableHtml: true, onActivateTick: true});
        } else {
            this.toastr.info(message, this.translateService.translate('TITLE.info'),
                {tapToDismiss: clickToDismiss, disableTimeOut: true, enableHtml: true, onActivateTick: true});
        }
    }

    priorityMessage(message: string, title?: string): ActiveToast<any> {
      return this.toastr.info(
        message, title ? title : this.translateService.translate('TITLE.newPriorityMessage'),
        { tapToDismiss: true, disableTimeOut: true, enableHtml: true, onActivateTick: true },
      );
    }

}
