<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'TITLE.confirmPassUsage' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card *ngIf="isCashPass(passInfo.passType)">
    <ion-card-header>
      <ion-card-title text-wrap>{{ titleMessage }}</ion-card-title>
      <ion-card-subtitle text-wrap *ngIf="subtitleMessage && subtitleMessage.length > 0">
        {{ subtitleMessage }}
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <dl>
        <dt>{{ 'LABEL.riderName' | translate }}</dt>
        <dd>{{ passInfo.assignedRiderName || 'LABEL.notAssigned' | translate }}</dd>

        <dt>{{ 'LABEL.currentBalance' | translate }}</dt>
        <dd>${{ passInfo.remainingBalance | number:'.2-2' }}</dd>

        <ng-container *ngIf="(passInfo.debitAmoutForRide || 0) > 0">
          <dt>{{ 'LABEL.fareOwed' | translate }}</dt>
          <dd>${{(passInfo.debitAmoutForRide || 0) | number:'.2-2'}}</dd>
        </ng-container>

        <div class="debit-amount" *ngIf="(passInfo.debitAmoutForRide || 0) <= 0">
          <span class="label">
            {{ 'LABEL.cashCollected' | translate }}
          </span>
          <div
            class="increment"
            incrementControl
            [maxValue]="passInfo.debitAmoutForRide"
            *ngIf="true"
            [showOutline]="true"
            [(ngModel)]="cashCollected"
          ></div>
        </div>

        <ng-container *ngIf="(passInfo.debitAmoutForRide || 0) > 0">
          <dt>{{ 'LABEL.balanceRemaining' | translate }}</dt>
          <dd>${{passInfo.remainingBalance - passInfo.debitAmoutForRide + +cashCollected | number:'.2-2'}}</dd>
        </ng-container>
      </dl>

    </ion-card-content>
  </ion-card>

  <!--*************************************************** Flex or SIUC passes ************************************************-->
  <ion-card *ngIf="!isCashPass(passInfo.passType)">
    <ion-card-header>
      <ion-card-title text-wrap>{{ titleMessage }}</ion-card-title>
      <ion-card-subheader text-wrap *ngIf="subtitleMessage && subtitleMessage.length > 0">
        {{ subtitleMessage }}
      </ion-card-subheader>
    </ion-card-header>
    <ion-card-content>
      <dl>
        <dt>{{ 'LABEL.assignedRider' | translate }}</dt>
        <dd>{{ passInfo.assignedRiderName || 'LABEL.notAssigned' | translate }}</dd>

        <ng-container *ngIf="passInfo.effectiveDate || passInfo.expirationDate">
          <dt>{{ 'LABEL.passEffectiveDate' | translate }}</dt>
          <dd>
            {{ !passInfo.effectiveDate ? ('LABEL.noBeginning' | translate) : (passInfo.effectiveDate | date:'MM/dd/yyyy') }}
            -
            {{ !passInfo.expirationDate ? ('LABEL.noExpiration' | translate) : (passInfo.expirationDate | date:'MM/dd/yyyy') }}
          </dd>
        </ng-container>

        <ng-container *ngIf="passInfo.remainingRides">
          <dt>{{ 'LABEL.numRidesRemain' | translate }}:</dt>
          <dd>{{ passInfo.remainingRides }}</dd>
        </ng-container>

        <ng-container *ngIf="passInfo.debitRideCountForRide">
          <dt>{{ 'LABEL.debitRideCount' | translate }}:</dt>
          <dd>{{ passInfo.debitRideCountForRide }}</dd>
        </ng-container>

        <ng-container *ngIf="passInfo.ridesRemainingAfterRideDebit">
          <dt>{{ 'LABEL.remainingRides' | translate }}:</dt>
          <dd>{{ passInfo.ridesRemainingAfterRideDebit }}</dd>
        </ng-container>
      </dl>
    </ion-card-content>
  </ion-card>

</ion-content>

<ion-footer>

  <ion-button
    color="primary"
    expand="block"
    (click)="provideRideClicked()"
    [disabled]="!passInfo.passIdIsValid">
    {{ 'ACTION.provideRide' | translate }}
    <ion-icon name="checkmark" slot="end"></ion-icon>
  </ion-button>

  <ion-button color="danger"
    expand="block"
    (click)="denyRideClicked()"
  >
    {{ 'ACTION.denyRide' | translate }}
    <ion-icon name="hand" slot="end"></ion-icon>
  </ion-button>
</ion-footer>
