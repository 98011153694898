<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="outline" color="secondary" (click)="dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title (click)="titleClick()">Fairmatic</ion-title>
    <ion-buttons slot="end">
      <ion-button color="secondary" fill="solid" (click)="enableAdminTools = !enableAdminTools">
        {{ (enableAdminTools ? "Hide" : "Show") }} Tools
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-list *ngIf="enableAdminTools" lines="full" class="info-list">
    <ion-item>
      <ion-note>Valid Device</ion-note>
      <ion-label>{{ isValidDevice$ | async | json }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-note>Valid Customer</ion-note>
      <ion-label>{{ isValidCustomer$ | async }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-note>Is Zendrive Setup</ion-note>
      <ion-label>{{ isZendriveSetup$ | async | json }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-note>Zendrive Driver Id</ion-note>
      <ion-label>{{ fairmaticDriverId$ | async }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-note>Insurance Period</ion-note>
      <ion-label>{{ currentInsurancePeriod$ | async }}</ion-label>
    </ion-item>
  </ion-list>

  <ion-row>
    <ion-col><h2>{{ 'LABEL.callHistory' | translate }}</h2></ion-col>
  </ion-row>

  <ng-container *ngIf="fairmaticHistory$ | async as fairmaticHistory">

    <ion-list class="log">
      <ion-item *ngIf="fairmaticHistory.length == 0">
        <ion-label class="ion-text-wrap"><i>{{ 'LABEL.callHistoryNoCalls' | translate }}</i></ion-label>
      </ion-item>
      <ion-item *ngFor="let logMessage of fairmaticHistory">
        <ion-note>{{ logMessage.time | date:'HH:mm:ss'}}</ion-note>
        <ion-label><pre><code>{{ logMessage.message }}</code></pre></ion-label>
      </ion-item>
    </ion-list>


  </ng-container>
</ion-content>

<ion-footer *ngIf="enableAdminTools" class="tools">
  <ion-toolbar>
    <ion-buttons>
      <ion-button color="primary" fill="solid" type="button" (click)="triggerEvent('setup')">Setup</ion-button>
      <ion-button color="primary" fill="solid" type="button" (click)="triggerEvent('goOnDuty')">On Duty</ion-button>
      <ion-button color="primary" fill="solid" type="button" (click)="triggerEvent('onTheWay')">On The Way</ion-button>
      <ion-button color="primary" fill="solid" type="button" (click)="triggerEvent('pickupPassenger')">Pick up</ion-button>
      <ion-button color="primary" fill="solid" type="button" (click)="triggerEvent('dropOffPassenger')">Drop off</ion-button>
      <ion-button color="primary" fill="solid" type="button" (click)="triggerEvent('goOffDuty')">Off Duty</ion-button>
      <ion-button color="primary" fill="solid" type="button" (click)="triggerEvent('status')">Get Status</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
