import { DispatchMessage, MessageResponseEnum } from '../models/dispatch-message';
import { Injectable, inject } from '@angular/core';

import { AppData } from '../data';
import { DispatcherService } from './dispatcher.service';
import { MessageResponse } from '../models/message-response';
import { NotificationService } from './notification.service';
import { SendPredefinedMessage } from '../models/send-predefined-message';
import { TranslateService } from './translate.service';

@Injectable({ providedIn: 'root' })
export class MessageService {

  public readonly data = inject(AppData);
  private readonly dispatcherService = inject(DispatcherService);
  private readonly notificationService = inject(NotificationService);
  private readonly translateService = inject(TranslateService);

  public dataSetup(messages: DispatchMessage[]): void {
    this.data.set('messages', messages.map(message => ({ ...message,  createdDate: new Date(message.createdDate), messageResponseType: Number(message.messageResponseType)})));
  }

  public async answer(id: number, dismiss: boolean, answer?: string): Promise<void> {
    try {
      const messageResponse: MessageResponse = {
        dismiss: dismiss,
        gpsReport: this.data.gpsReport,
        originalMessageId: id,
        answer: answer,
      };

      const messages = this.data.messages;
      const matchingMessage = messages.find(x => x.id === id);

      if (matchingMessage) {
        const index = messages.indexOf(matchingMessage, 0);
        if (index === -1) { return; }
        if (dismiss) {
          messages.splice(index, 1);
        } else {
          matchingMessage.messageResponseType = MessageResponseEnum.None;
        }
        this.data.set('messages', messages);
      }
      await this.dispatcherService.dismissDispatchMessage(messageResponse);
    } catch (err) {
      console.error(err);
      this.notificationService.error(this.translateService.translate('MESSAGE.genericError'), null, true);
    }
  }

  public async sendMessage(id: number): Promise<void> {
    const request: SendPredefinedMessage = { id, gpsReport: this.data.gpsReport };
    await this.dispatcherService.predefinedMessage(request);
  }

}
