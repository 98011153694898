import { UUID } from "angular2-uuid";
import { environment } from "src/environments/environment";

export type LocalSettings = {
  showClock: boolean;
  uuid: string;
  customerName: string;
  customerId: number;
  description: string;
  dispatcherUrl: string;
  environment: string;
  automateClickThrough: boolean;
  debuggingSounds: boolean;
  isMockEnvironmentEnabled: boolean;
}

export const defaultLocalSettings: LocalSettings = {
  showClock: true,
  uuid: UUID.UUID(),
  customerId: null,
  customerName: '',
  description: '',
  automateClickThrough: false,
  debuggingSounds: false,
  dispatcherUrl: environment.defaultDispatcherUrl,
  isMockEnvironmentEnabled: false,
  environment: undefined,
};
