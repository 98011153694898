import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { AppData } from '../data';
import { DeviceService } from './device.service';

const routeInfo = {
  "messages": [],
  "route": {
    "id": 587040,
    "preTrip": {
      "scheduledStart": "2023-07-11T01:00:00",
      "scheduledEnd": "2023-07-11T01:05:00",
      "hasBeenStarted": false,
      "hasBeenCompleted": false,
      "inspectionIsComplete": false,
      "inspectionItems": [{
        "inspectionItemId": 1,
        "description": "Tires",
        "questionType": 0,
        "isCritical": true,
        "result": null,
        "textResult": null,
        "serviceCategory": "General Condition",
      }, {
        "inspectionItemId": 2,
        "description": "Brakes",
        "questionType": 0,
        "isCritical": true,
        "result": null,
        "textResult": null,
        "serviceCategory": "General Condition",
      }, {
        "inspectionItemId": 3,
        "description": "Coolant Color",
        "questionType": 1,
        "isCritical": true,
        "result": null,
        "textResult": null,
        "serviceCategory": "General Condition",
      },
      ],
    },
    "break": {
      "scheduledStart": "2023-07-11T11:00:00",
      "scheduledEnd": "2023-07-11T11:07:00",
      "hasBeenStarted": false,
      "hasBeenCompleted": false,
      "inspectionIsComplete": false,
      "inspectionItems": [],
    },
    "scheduledStart": "2023-07-11T01:00:00",
    "scheduledEnd": "2023-07-11T23:55:00",
    "jobs": [
      {
        "epassId": null,
        "rideId": 1422307,
        "flagStopId": null,
        "jobType": "1",
        "scheduledTime": "2023-07-11T11:10:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": "2023-07-11T11:10:00",
        "jobEnabled": true,
        "location": {
          "coordinate": {
            "latitude": 45.528395,
            "longitude": -122.676619,
          },
          "name": "1422307 - AMTRAK PORTLAND",
          "address": "800 NW 6th Ave",
          "address2": null,
          "city": "Portland",
          "state": "OR",
          "zipCode": "97209",
        },
        "riderPhone": null,
        "riderFirstName": "Jk",
        "riderLastName": "Client1",
        "rideFare": 0.0,
        "additionalRideFare": 2.23,
        "defaultFareTypeId": "4",
        "ambulatorySeats": 1,
        "wheelchairSeats": 0,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 1,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "31636",
        "rideComments": null,
        "riderComments": null,
        "willCall": false,
        "priority": "Pickup",
        "priorityTime": "2023-07-11T11:10:00",
        "specialAssistances": null,
        "riderSignatureRequired": true,
        "driverSignatureRequired": true,
        "attendantSignatureRequired": true,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": null,
        "pickupPhone": null,
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      },
      {
        "epassId": null,
        "rideId": 1422307,
        "flagStopId": null,
        "jobType": "2",
        "scheduledTime": "2023-07-18T03:22:52",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": "2023-07-18T03:22:52",
        "jobEnabled": true,
        "location": {
          "coordinate": {
            "latitude": 45.557984,
            "longitude": -122.545079,
          },
          "name": "1422307 - BUDGET INN PORTLAND AIRPORT",
          "address": "11417 NE Sandy Blvd",
          "address2": null,
          "city": "Portland",
          "state": "OR",
          "zipCode": "97220",
        },
        "riderPhone": null,
        "riderFirstName": "Jk",
        "riderLastName": "Client1",
        "rideFare": 0.0,
        "additionalRideFare": 2.23,
        "defaultFareTypeId": "0",
        "ambulatorySeats": 1,
        "wheelchairSeats": 0,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "31636",
        "rideComments": null,
        "riderComments": null,
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T11:10:00",
        "specialAssistances": null,
        "riderSignatureRequired": true,
        "driverSignatureRequired": true,
        "attendantSignatureRequired": true,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": null,
        "pickupPhone": null,
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      },
      {
        "rideId": 71783,
        "flagStopId": null,
        "jobType": 1,
        "scheduledTime": "2023-07-11T11:15:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": true,
        "location": {
          "coordinate": {
            "latitude": 35.81571137678315,
            "longitude": -82.65433911896524,
          },
          "name": "11 Fisher Ln",
          "address": "11 Fisher Lane ",
          "address2": "",
          "city": "Marshall",
          "state": "NC",
          "zipCode": "28753",
        },
        "riderPhone": "",
        "riderFirstName": "Ronnie",
        "riderLastName": "Dio",
        "rideFare": 16.57,
        "ambulatorySeats": 0,
        "wheelchairSeats": 1,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 1,
        "numberOfChildren": 1,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T12:00:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "",
        "pickupPhone": "",
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71784,
        "flagStopId": null,
        "jobType": 1,
        "scheduledTime": "2023-07-11T11:15:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": true,
        "location": {
          "coordinate": {
            "latitude": 35.81571137678315,
            "longitude": -82.65433911896524,
          },
          "name": "11 Fisher Ln",
          "address": "11 Fisher Lane ",
          "address2": "",
          "city": "Marshall",
          "state": "NC",
          "zipCode": "28753",
        },
        "riderPhone": "",
        "riderFirstName": "Vivian",
        "riderLastName": "Campbell",
        "rideFare": 16.57,
        "ambulatorySeats": 0,
        "wheelchairSeats": 1,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T12:00:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "",
        "pickupPhone": "",
        "willCallReady": false,
        "ePassId": "",
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71785,
        "flagStopId": null,
        "jobType": 1,
        "scheduledTime": "2023-07-11T11:30:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": true,
        "location": {
          "coordinate": {
            "latitude": 35.81571137678315,
            "longitude": -82.65433911896524,
          },
          "name": "11 Fisher Ln",
          "address": "11 Fisher Lane ",
          "address2": "",
          "city": "Marshall",
          "state": "NC",
          "zipCode": "28753",
        },
        "riderPhone": "",
        "riderFirstName": "Doug",
        "riderLastName": "Aldrich",
        "rideFare": 16.57,
        "ambulatorySeats": 0,
        "wheelchairSeats": 1,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T12:05:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "",
        "pickupPhone": "",
        "willCallReady": false,
        "ePassId": "",
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71783,
        "flagStopId": null,
        "jobType": 2,
        "scheduledTime": "2023-07-11T12:00:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": false,
        "location": {
          "coordinate": {
            "latitude": 35.96192025715768,
            "longitude": -83.92348120066366,
          },
          "name": "Sunsphere",
          "address": "810 W Clinch Ave",
          "address2": "",
          "city": "Knoxville",
          "state": "TN",
          "zipCode": "37902",
        },
        "riderPhone": "",
        "riderFirstName": "Ronnie",
        "riderLastName": "Dio",
        "rideFare": 16.57,
        "ambulatorySeats": 0,
        "wheelchairSeats": 1,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T12:00:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "",
        "pickupPhone": "",
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71784,
        "flagStopId": null,
        "jobType": 2,
        "scheduledTime": "2023-07-11T12:00:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": false,
        "location": {
          "coordinate": {
            "latitude": 35.96192025715768,
            "longitude": -83.92348120066366,
          },
          "name": "Sunsphere",
          "address": "810 W Clinch Ave",
          "address2": "",
          "city": "Knoxville",
          "state": "TN",
          "zipCode": "37902",
        },
        "riderPhone": "",
        "riderFirstName": "Vivian",
        "riderLastName": "Cambell",
        "rideFare": 16.57,
        "ambulatorySeats": 0,
        "wheelchairSeats": 1,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T12:00:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "",
        "pickupPhone": "",
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71799,
        "flagStopId": null,
        "jobType": 1,
        "scheduledTime": "2023-07-11T12:15:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": true,
        "location": {
          "coordinate": {
            "latitude": 35.81570047794571,
            "longitude": -82.65411458772873,
          },
          "name": "Home",
          "address": "4 Fisher Ln",
          "address2": "",
          "city": "Marshall",
          "state": "NC",
          "zipCode": "28753",
        },
        "riderPhone": "(828)380-1889",
        "riderFirstName": "Summer",
        "riderLastName": "Smith",
        "rideFare": 16.57,
        "ambulatorySeats": 1,
        "wheelchairSeats": 0,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T12:30:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "(828)380-1889",
        "pickupPhone": "(828)380-1889",
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71799,
        "flagStopId": null,
        "jobType": 2,
        "scheduledTime": "2023-07-11T12:30:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": false,
        "location": {
          "coordinate": {
            "latitude": 35.703997496186226,
            "longitude": -82.5687291028237,
          },
          "name": "Publix",
          "address": "165 Weaver Blvd",
          "address2": "",
          "city": "Weaverville",
          "state": "NC",
          "zipCode": "28787",
        },
        "riderPhone": "(828)380-1889",
        "riderFirstName": "Summer",
        "riderLastName": "Smith",
        "rideFare": 16.57,
        "ambulatorySeats": 1,
        "wheelchairSeats": 0,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T12:30:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "(828)380-1889",
        "pickupPhone": "(828)380-1889",
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71800,
        "flagStopId": null,
        "jobType": 1,
        "scheduledTime": "2023-07-11T13:30:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": true,
        "location": {
          "coordinate": {
            "latitude": 35.81561128065741,
            "longitude": -82.6542036831817,
          },
          "name": "Home",
          "address": "3 Fisher Ln",
          "address2": "",
          "city": "Marshall",
          "state": "NC",
          "zipCode": "28753",
        },
        "riderPhone": "",
        "riderFirstName": "Morty",
        "riderLastName": "Smith",
        "rideFare": 16.57,
        "ambulatorySeats": 1,
        "wheelchairSeats": 0,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T13:45:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "",
        "pickupPhone": "",
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      }, {
        "rideId": 71800,
        "flagStopId": null,
        "jobType": 2,
        "scheduledTime": "2023-07-11T13:45:00",
        "arriveTime": null,
        "performTime": null,
        "onTheWayTime": null,
        "estimatedTimeOfArrival": null,
        "jobEnabled": false,
        "location": {
          "coordinate": {
            "latitude": 35.703997496186226,
            "longitude": -82.5687291028237,
          },
          "name": "Publix",
          "address": "165 Weaver Blvd",
          "address2": "",
          "city": "Weaverville",
          "state": "NC",
          "zipCode": "28787",
        },
        "riderPhone": "",
        "riderFirstName": "Morty",
        "riderLastName": "Smith",
        "rideFare": 16.57,
        "ambulatorySeats": 1,
        "wheelchairSeats": 0,
        "stretcherCount": 0,
        "companions": 0,
        "escorts": 0,
        "numberOfChildren": 0,
        "units": 1,
        "accountCode": "ACCT-001",
        "rideComments": "",
        "riderComments": "",
        "willCall": false,
        "priority": "Dropoff",
        "priorityTime": "2023-07-11T13:45:00",
        "specialAssistances": [],
        "riderSignatureRequired": true,
        "driverSignatureRequired": false,
        "attendantSignatureRequired": false,
        "authNumber": "",
        "liftRequired": false,
        "dropoffPhone": "",
        "pickupPhone": "",
        "willCallReady": false,
        "ePassId": null,
        "supportsDuplicatingNoShow": true,
      },
    ],
    "driverFirstName": "Justinin",
    "driverLastName": "Greenwood",
    "description": "Justin's Trip - No Touchy",
    "vehicleNumber": "701",
    "endDepoLocation": {
      "coordinate": {
        "latitude": 35.96192025715768,
        "longitude": -83.92348120066366,
      },
      "name": "Sunsphere",
      "address": "810 W Clinch Ave",
      "address2": "",
      "city": "Knoxville",
      "state": "TN",
      "zipCode": "37902",
    },
    "hasBeenStarted": true,
    "driverSignatureRequired": true,
    "routeNumber": 666,
    "flagStopsEnabled": true,
    "fixedRoutesEnabled": true,
    "allowQuickPerform": true,
    "allowOnTheWay": true,
    "riderTypes": [{
      "riderTypeId": 1,
      "isActive": false,
      "riderTypeDescription": "Saluki Student",
      "currentNumberOnboard": 0,
      "defaultFare": 0,
      "onboardPassIds": [],
    }, {
      "riderTypeId": 2,
      "isActive": true,
      "riderTypeDescription": "General",
      "currentNumberOnboard": 0,
      "defaultFare": 0,
      "onboardPassIds": [],
    }, {
      "riderTypeId": 3,
      "isActive": true,
      "riderTypeDescription": "Student",
      "currentNumberOnboard": 0,
      "defaultFare": 0,
      "onboardPassIds": [],
    }, {
      "riderTypeId": 4,
      "isActive": true,
      "riderTypeDescription": "Ramon Student",
      "currentNumberOnboard": 0,
      "defaultFare": 0,
      "onboardPassIds": [],
    },
    ],
    "scheduledFlagStops": [],
    "vehicleId": 27,
    "driverId": 2,
  },
  "noShowOptions": [{
    "id": 0,
    "displayValue": "No Client",
    "createsCopyOfRide": false,
  }, {
    "id": 2,
    "displayValue": "Cancel At Door",
    "createsCopyOfRide": false,
  }, {
    "id": 3,
    "displayValue": "Not Ready",
    "createsCopyOfRide": true,
  }, {
    "id": 4,
    "displayValue": "Admin Cancel",
    "createsCopyOfRide": false,
  }, {
    "id": 5,
    "displayValue": "Not Ready, Will Return",
    "createsCopyOfRide": true,
  },
  ],
}
  ;
const routeSummary = [
  {
    "id": 573691,
    "number": 1,
    "date": "2023-07-11T00:00:00",
    "availableStart": "2023-07-11T12:00:00Z",
    "scheduledStart": "2023-07-11T12:00:00Z",
    "availableEnd": "2023-07-11T21:00:00Z",
    "scheduledEnd": "2023-07-11T21:00:00Z",
    "description": "1",
    "vehicleNumber": "1",
    "hasBeenStarted": false,
    "expectedRouteStartOdometer": 0.0,
    "hasFailedPreRouteInspection": false,

  },
  {
    "id": 573704,
    "number": 1,
    "date": "2023-07-12T00:00:00",
    "availableStart": "2023-07-12T12:00:00Z",
    "scheduledStart": "2023-07-12T12:00:00Z",
    "availableEnd": "2023-07-12T21:00:00Z",
    "scheduledEnd": "2023-07-12T21:00:00Z",
    "description": "1",
    "vehicleNumber": "1",
    "hasBeenStarted": false,
    "expectedRouteStartOdometer": 0.0,
    "hasFailedPreRouteInspection": false,
  },
];
const urls = [
  {
    url: '/registerDevice',
    responseNegative: {
    },
    responsePositive: {
      "ionicChannel": "testing",
      "logVerbosity": 0,
      "customerId": 300,
      "customerName": "PScope",
    },
  },
  {
    url: '/startRoute',
    responsePositive: routeInfo,
  },
  {
    url: '/routePreview',
    responsePositive: routeInfo,
  },
  {
    url: '/routeStatusUpdate',
    responsePositive: {
      "LoggedOutDate": null,
      "DriverFirstName": null,
      "DriverLastName": null,
      "VehicleNumber": null,
      "RouteNumber": null,
      "NeedUpdateFromServer": false,
    },
  },
  {
    url: '/login',
    responseNegative: {
      "errors": {
        "pin": [
          "Invalid Pin",
        ],
        "vehicleId": [
          "Invalid vehicleId",
        ],
      },
      "title": "One or more validation errors occurred.",
      "status": 400,
      "traceId": "43e67c59-1fcd-4cae-b45b-402337e3c947",
    },
    responsePositive: {
      "sessionId": "8dc1eba6-1e42-4f5a-a0b9-ce3d864dcdc6",
      "paraPassEnabled": true,
      "routeSummaries": routeSummary,
      "predefinedMessages": [
        {
          "id": 0,
          "displayValue": "EMERGENCY ALARM",
        },
        {
          "id": 1,
          "displayValue": "10-4",
        },
        {
          "id": 2,
          "displayValue": "10-24",
        },
        {
          "id": 3,
          "displayValue": "10-24/10-98",
        },
        {
          "id": 4,
          "displayValue": "Signal 15",
        },
        {
          "id": 5,
          "displayValue": "Negative",
        },
        {
          "id": 6,
          "displayValue": "Disregard",
        },
        {
          "id": 7,
          "displayValue": "Out of Service",
        },
        {
          "id": 8,
          "displayValue": "In Service",
        },
        {
          "id": 9,
          "displayValue": "Request Voice",
        },
        {
          "id": 10,
          "displayValue": "Need to Refuel",
        },
        {
          "id": 11,
          "displayValue": "Weather Conditions?",
        },
        {
          "id": 12,
          "displayValue": "Passenger still being treated",
        },
        {
          "id": 13,
          "displayValue": "No Caregiver, Delayed Drop-off",
        },
        {
          "id": 14,
          "displayValue": "Caregiver Arrived",
        },
        {
          "id": 15,
          "displayValue": "ETA-5 Minutes",
        },
        {
          "id": 16,
          "displayValue": "ETA-10 Minutes",
        },
        {
          "id": 17,
          "displayValue": "ETA-15 Minutes",
        },
        {
          "id": 18,
          "displayValue": "MINOR ON BOARD",
        },
        {
          "id": 19,
          "displayValue": "Emergency resolved",
        },
        {
          "id": 20,
          "displayValue": "Emergency activated by mistake",
        },
      ],
      "fareTypes": [
        {
          "id": 0,
          "displayValue": "Cash",
        },
        {
          "id": 1,
          "displayValue": "None",
        },
        {
          "id": 2,
          "displayValue": "Ticket",
        },
        {
          "id": 3,
          "displayValue": "Voucher",
        },
        {
          "id": 4,
          "displayValue": "Billing",
        },
        {
          "id": 5,
          "displayValue": "Balance",
        },
        {
          "id": 6,
          "displayValue": "External Payment",
        },
        {
          "id": 7,
          "displayValue": "eTicket",
        },
        {
          "id": 8,
          "displayValue": "External",
        },
      ],
    },
  },
  {
    url: '/passInformation',
    responsePositive: {
      "passIdIsValid": true,
      "passType": 1,
      "integrationFailure": false,
      "isActive": true,
      "isAssignedToRiderOnRide": true,
      "assignedRiderName": "Ronnie Dio",
      "isSufficientForRide": true,
      "effectiveDate": new Date(),
      "expirationDate": null,
      "remainingBalance": 10001.00,
      "remainingRides": 900,
      "debitAmoutForRide": 16.57,
      "balanceRemainingAfterRideDebit": 9931.31,
      "debitRideCountForRide": 1,
      "ridesRemainingAfterRideDebit": 899,
      "isExpired": false,
      "isNotYetEffective": false,
      "riderTypeId": 2,
      "siucStudentIsActive": false,
      "siucMassTransitEnabled": false,
      "siucDawgTagIsValid": false,
    },
  },
  {
    url: '/routeSummaries',
    responsePositive: routeSummary,
  },
  {
    url: '/registerDevice',
    responsePositive: {
      "ionicChannel": "dev",
      "logVerbosity": 0,
      "customerId": 999,
      "customerName": "demo_customer",
    },
  },
];

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
  constructor(
    private readonly data: AppData,
    protected deviceService: DeviceService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.data.online || this.data.offlineMode) {
      throwError(() => new HttpErrorResponse(({ status: 0, statusText: 'No Internet Connection' })));
    }

    if (!this.data.localSettings.isMockEnvironmentEnabled) {
      return next.handle(request);
    }

    console.info("MockRequest", request?.body, request);

    for (const element of urls) {
      if (request.url.indexOf(element.url) > 0) {
        if (element.url === '/login') {
          if (request.body.pin === '1234' || request.body.pin === 'Demo1234') {
            return of(new HttpResponse({ status: 200, body: element.responsePositive }));
          } else {
            return throwError(() => new HttpErrorResponse(({ status: 400, statusText: JSON.stringify(element.responseNegative) })));
          }
        }
        else if (element.url === '/startRoute') {
          return of(new HttpResponse({ status: 200, body: element.responsePositive }));
        }
        else if (element.url === '/passInformation') {
          if (request.body.passId === '1234') {
            return of(new HttpResponse({ status: 200, body: element.responsePositive }));
          }
          else {
            return of(new HttpResponse({ status: 200 }));
          }
        }

        const response = new HttpResponse({ status: 200, body: element.responsePositive });
        return of(response);
      }
    }
    return next.handle(request);
  }
}
