import { Observable, switchMap, tap } from "rxjs";

import { ImageResponse } from "../models/image";
import { Injectable } from "@angular/core";
import { ServerProxyService } from "./server-proxy.service";

@Injectable({
  providedIn: 'root',
})

export class ImageService {
  constructor(private proxiesService: ServerProxyService) {
}

  public riderImage(sessionId: string, riderImageId: string): Observable<string> {
    return this.proxiesService.riderImage(sessionId, riderImageId);
  };
}
