import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

import { ConnectionStatus } from '@capacitor/network';
import { SignalDisplayStrength } from 'src/app/services/device.service';

@Component({
  selector: 'signal-indicator',
  templateUrl: `./signal-indicator.component.html`,
  styleUrls: [`./signal-indicator.component.scss`],
})
export class SignalIndicatorComponent implements OnInit, OnDestroy {

  @Input() networkStatus: Observable<ConnectionStatus>;
  @Input() connectionType: string;
  public signalObject = SIGNAL_PROPS[SignalDisplayStrength.Full];
  private readonly destroyed$ = new Subject<boolean>();

  constructor() { }

  ionicViewWillEnter() {
    this.signalObject = SIGNAL_PROPS[this.connectionType === 'none' ? SignalDisplayStrength.None : SignalDisplayStrength.Full];
  }

  ngOnInit(): void {
    this.networkStatus
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status: ConnectionStatus) => {
        this.signalObject = SIGNAL_PROPS[status.connected ? SignalDisplayStrength.Full : SignalDisplayStrength.None];
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

const SIGNAL_PROPS = {
  // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  // <path d="M12 5h4V1zm0 11V5L1 16z" fill-opacity=".3"/><path d="M14 16h2v-2h-2v2zm0-9v5h2V7h-2z"/></svg>
  0: {
    fillOpacity: 0.3,
    d1: 'M12 5h4V1zm0 11V5L1 16z',
    d2: 'M14 16h2v-2h-2v2zm0-9v5h2V7h-2z',
  },
  // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  // <path fill-opacity=".3" d="M1 16h15V1z"/></svg>
  1: {
    fillOpacity: 0.3,
    d1: 'M1 16h15V1z',
    d2: '',
  },
  // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  // <path fill-opacity=".3" d="M1 16h15V1z"/><path d="M1 16h7V9z"/></svg>
  2: {
    fillOpacity: 0.3,
    d1: 'M1 16h15V1z',
    d2: 'M1 16h7V9z',
  },
  // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  // <path fill-opacity=".3" d="M1 16h15V1z"/><path d="M1 16h9V7z"/></svg>
  3: {
    fillOpacity: 0.3,
    d1: 'M1 16h15V1z',
    d2: 'M1 16h9V7z',
  },
  // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  // <path fill-opacity=".3" d="M1 16h15V1z"/><path d="M1 16h11V5z"/></svg>
  4: {
    fillOpacity: 0.3,
    d1: 'M1 16h15V1z',
    d2: 'M1 16h11V5z',
  },
  // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  // <path d="M1 16h15V1z"/></svg>
  5: {
    fillOpacity: 1,
    d1: null,
    d2: 'M1 16h15V1z',
  },
};
