<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'TITLE.selectJobs' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form id="selectGroupArriveForm" (submit)="onSubmit()">
    <p>{{ jobs.length || 0 }} {{ 'MESSAGE.jobsToPickup' | translate }}</p>
    <p>{{ 'MESSAGE.selectToMarkArrive' | translate }}</p>
    <ng-container *ngFor="let job of jobs">
      <ion-item [ngClass]="jobTypes[job.jobType]">
        <ion-checkbox slot="start" (ionChange)="onSelectionChange($event.detail, job.rideId)" [checked]="true"></ion-checkbox>
        <ion-label class="label">
          <span class="time" [ngClass]="{ 'late' : job.inThePast }">
            {{ !job.scheduledTime ? '00:00' : job.scheduledTime | amDateFormat:'HH:mm' }}
            {{ job.jobType == 1 ? ('LABEL.pickup' | translate) : ('ACTION.dropOff' | translate) }}:
          </span>
          <span class="rider">{{ handleName(job.riderFirstName, job.riderLastName) }} {{ showClientId ? "(" + job.riderId + ")" : "" }}</span>
          <address>
            <ng-container *ngIf="job.location.name">{{ job.location.name }} - </ng-container>
            {{ job.location.address }},
            <ng-container *ngIf="job.location.address2">{{ job.location.address2 }},</ng-container>
            {{ job.location.city }}, {{ job.location.state }}, {{ job.location.zipCode }}
          </address>
        </ion-label>
      </ion-item>
    </ng-container>
  </form>
</ion-content>

<ion-footer>
  <ion-button type="submit" form="selectGroupArriveForm" color="primary" fill="solid" expand="block">
    <ion-icon name="locate" slot="start"></ion-icon>
    <span>{{ 'ACTION.arrive' | translate }}</span>
  </ion-button>
  <ion-button type="button" fill="outline" expand="block" (click)="onCancel()">{{ 'ACTION.cancel' | translate }}</ion-button>
</ion-footer>
