import { DeviceContext, OperatorContext } from '../models/location-context';
import { LoginCredentials, LoginResponse } from '../models/login';
import { Observable, of } from 'rxjs';
import { PassDetails, PassInformationRequestObject } from '../models/pass';
import { RouteStatusUpdate, RouteStatusUpdateResult } from '../models/route-status-update';

import { AppData } from '../data';
import { Device } from '../models/device';
import { GpsReport } from '../models/gps-report';
import { Injectable } from '@angular/core';
import { Location } from '@transistorsoft/capacitor-background-geolocation';
import { NativePluginService } from './plugin/native-plugins.service';
import { RegistrationResult } from '../models/registration';
import { RouteSummary } from '../models/route-summary';
import { RouteUpdate } from '../models/route-update';
import { SafeHttpService } from './safe-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServerProxyService {
  paraScopeGpsReportingEndpoint: string;
  paraScopeGpsReportingEndpointToken: string;

  scenario: string = null;

  private tokenCode() {
    if (!!this.paraScopeGpsReportingEndpointToken) {
      return `?code=${this.paraScopeGpsReportingEndpointToken}`;
    }
    return '';
  }

  constructor(
    private readonly data: AppData,
    private safeHttp: SafeHttpService,
    private pluginService: NativePluginService) {
  }

  riderImage(sessionId: string, riderImageId: string): Observable<string> {
    return this.safeHttp.getAsync<string>(this.dispatcherUrl + `riderImage/${sessionId}/${riderImageId}`);
  }

  registerDevice(device: Device): Observable<RegistrationResult> {
    return this.safeHttp.postAsync<RegistrationResult>(this.dispatcherUrl + 'registerDevice', device);
  }

  login(login: LoginCredentials): Observable<LoginResponse> {
    return this.safeHttp.postAsync<LoginResponse>(this.dispatcherUrl + `login?scenario=${this.scenario}`, login);
  }

  resetSession(sessionId: string): Observable<LoginResponse> {
    return this.safeHttp.getAsync(this.dispatcherUrl + `resetRoute/${sessionId}`);
  }

  startRoute(routeId: number, gpsReport: GpsReport): Promise<RouteUpdate> {
    return this.safeHttp.post<RouteUpdate>(this.dispatcherUrl + `startRoute?scenario=${this.scenario}`,
      {routeId, gpsReport, sessionId: this.sessionId });
  }

  routePreview(routeId: number): Promise<RouteUpdate> {
    return this.safeHttp.post<RouteUpdate>(this.dispatcherUrl + `routePreview`,
      { routeId: routeId, sessionId: this.sessionId });
  }

  routeUpdate(scenario?: string): Promise<RouteUpdate> {
    return this.safeHttp.get<RouteUpdate>(this.dispatcherUrl + `routeUpdate/${this.sessionId}?scenario=${this.scenario}`);
  }

  routeStatusUpdate(pendingUpdates: RouteStatusUpdate): Promise<RouteStatusUpdateResult> {
    return this.safeHttp.post<RouteStatusUpdateResult>(this.dispatcherUrl + `routeStatusUpdate?scenario=${this.scenario}`, pendingUpdates);
  }

  routeSummaries(): Promise<RouteSummary[]> {
    return this.safeHttp.get<RouteSummary[]>(this.dispatcherUrl + `routeSummaries/${this.sessionId}`);
  }

  tripSync(customerId: number): Promise<Boolean> {
    return this.safeHttp.get(this.dispatcherUrl + `IsTripSync/${customerId}`);
  }

  passInformation(passInformationRequest: PassInformationRequestObject): Promise<PassDetails> {
    return this.safeHttp.post<PassDetails>(this.dispatcherUrl + `passInformation`, passInformationRequest, null, 60000);
  }

  sendLocation(locationInfo: { location: Location, device: DeviceContext, operator: OperatorContext }): Observable<any> {
    if (environment.features.sendLocationUpdates) {
      const url = `${this.paraScopeGpsReportingEndpoint}/UpdateLocation/${locationInfo.operator.customerId}/${this.tokenCode()}`;
      console.info(`Sending location for AVL to "${url}"`, locationInfo);
      return this.safeHttp.postAsync(url, locationInfo);
    }
    else {
      return of(null);
    }
  }

  private get dispatcherUrl(): string {
    const env = this.data.localSettings.dispatcherUrl;
    if (env) {
      return env;
    } else {
      console.warn(`No environment found for "${this.data.localSettings.environment}"`);
      //see also: environment.ecolane.ts
      if (this.pluginService.isNativePlatform && environment.features.defaultEnvToProd) {
        console.info(`Using "Production Live": "${environment.dispatcherEndpoints[0].name}"`);
        return environment.dispatcherEndpoints[1].url;//Prod environment named "Production Live"
      }
      console.info(`Using first environment in list: "${environment.dispatcherEndpoints[0].name}"`);
      return environment.dispatcherEndpoints[0].url;

    }
  }


  private get sessionId(): string {
    return this.data.sessionId;
  }
}
