<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'TITLE.passStatus' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-card>
    <ion-card-content>
      <h2 *ngIf="insufficientErrorMessage" class="error">{{ insufficientErrorMessage }}</h2>
      <dl>
        <ng-container *ngFor="let displayObj of displayStrings">
          <dt>{{ displayObj.label }}:</dt>
          <dd [class.error]="displayObj.isError">{{ displayObj.value }}</dd>
        </ng-container>
      </dl>
    </ion-card-content>
  </ion-card>
</ion-content>

<ion-footer>
  <ion-button expand="block" color="primary" (click)="provideRideClicked()">{{ 'ACTION.provideRide' | translate }}</ion-button>
  <ion-button expand="block" color="danger" (click)="denyRideClicked()">{{ 'ACTION.denyRide' | translate }}</ion-button>
</ion-footer>
