import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-pass-entry',
  templateUrl: './pass-entry.page.html',
  styleUrls: ['./pass-entry.page.scss'],
})
export class PassEntryPage implements AfterViewInit {


  @ViewChild('passInput', { static: true }) passInput: ElementRef;

  timeout: any = null;
  passId = '';
  constructor(private modalController: ModalController) { }

  async passScanned(){
    if (this.timeout){
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(async () => {

      await this.modalController.dismiss(this.passId);

    }, 500);
  }
  async dismiss(){
    await this.modalController.dismiss();
  }

  ngAfterViewInit() {
    setTimeout(() => {

      this.passInput?.nativeElement?.focus();

    }, 500);
  }

}
