export enum PassTypeEnum {
  Cash = 1,
  Flex = 2,
  Siuc = 3
}

export enum PassInformationPageResultEnum {
  Sufficient,
  InsufficientProvide,
  ServerOfflineProvide,
  Deny,
  Invalid
}

export type Pass = {
  id: string;
  riderTypeId?: number;
}

export type PassDetails = {
  passIdIsValid: boolean;
  isActive: boolean;
  isAssignedToRiderOnRide: boolean;
  assignedRiderName: string;
  isSufficientForRide: boolean;
  isExpired: boolean;
  isNotYetEffective: boolean;
  expirationDate: Date;
  effectiveDate: Date;
  remainingBalance: number;
  remainingRides: number;
  debitAmoutForRide: number;
  balanceRemainingAfterRideDebit: number;
  debitRideCountForRide: number;
  ridesRemainingAfterRideDebit: number;
  passType: PassTypeEnum;
  riderTypeId?: number;
  integrationFailure?: boolean;
  siucMassTransitEnabled: boolean;
  siucStudentIsActive: boolean;
  siucDawgTagIsValid: boolean;
}

export type PassInformationRequestObject = {
  sessionId: string;
  rideId: number;
  passId: string;
  numberOfchildren: number;
  numberOfEscorts: number;
  latitude: number;
  longitude: number;
}

export type PassInformationDisplayString = {
  label: string;
  value: string;
  isError?: boolean;
}

export function isCashPass(passType: PassTypeEnum): boolean {
  return passType === PassTypeEnum.Cash;
}

export function isFlexPass(passType: PassTypeEnum): boolean {
  return passType === PassTypeEnum.Flex;
}

export function isSiucPass(passType: PassTypeEnum): boolean {
  return passType === PassTypeEnum.Siuc;
}

export function isGodModePass(pass: PassDetails): boolean {
  return (pass.passType === PassTypeEnum.Flex && !pass.remainingRides && !pass.effectiveDate && !pass.expirationDate);
}
