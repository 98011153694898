import { Component, inject } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { PermissionsService } from 'src/app/services/permissions.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent {

  private readonly modalController = inject(ModalController);
  public readonly permissionsService = inject(PermissionsService);
  public readonly appName = environment.appName;
  public permissions$ = this.permissionsService.hasAllNecessaryPermissions();

  public dismiss(): Promise<boolean> {
    return this.modalController.dismiss();
  }

}
