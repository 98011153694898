import { CheckboxChangeEventDetail, ModalController } from "@ionic/angular";
import { Component, OnInit, inject } from "@angular/core";
import { Job, JobType } from "src/app/models/job";

import { ScheduledEvent } from "../../job-list/job-list.component";
import { UtilityService } from "src/app/services/utility.service";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: './group-arrive.dialog.html',
  styleUrls: [ './group-arrive.dialog.scss' ],
})
export class GroupArriveDialog implements OnInit {
  showClientId: boolean = environment.features.showClientId;

  private readonly modalController = inject(ModalController);
  private readonly utility = inject(UtilityService);

  public jobs: ScheduledEvent<Job>[];
  private selected: number[] = [];
  public readonly jobTypes = JobType;

  ngOnInit(): void {
    this.selected = this.jobs.map(j => j.rideId);
  }

  public onSelectionChange(event: CheckboxChangeEventDetail, rideId: number) {
    const rideIndex = this.selected.findIndex(id => id === rideId);
    if (event.checked) {
      if (rideIndex > -1) { return; }
      this.selected.push(rideId);
    } else {
      if (rideIndex === -1) { return; }
      this.selected.splice(rideIndex, 1);
    }
  }

  public handleName(first: string, last: string): string {
    return this.utility.handleName(first, last);
  }

  public onCancel(): void {
    this.modalController.dismiss();
  }

  public onSubmit(): void {
    this.modalController.dismiss(this.selected);
  }

}
