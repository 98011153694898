import { Component, OnDestroy } from '@angular/core';
import { Language, TranslateService } from '../../services/translate.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'translate-toggle',
  templateUrl: `./translate-toggle.component.html`,
  styleUrls: [`./translate-toggle.component.scss`],
})
export class TranslateToggleComponent implements OnDestroy {

  public language: Language;
  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly translateService: TranslateService,
  ) {
    this.translateService.currentLanguage$.pipe(
      tap(value => this.language = value),
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  onChange(): void {
    this.translateService.setLanguage(this.language);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
