<ion-card class="job-card" role="button" tabindex="0" (click)="showGroupDetails()">
  <ion-card-header class="job-card-header">
    <div>
      <ion-card-title class="title">
        <span class="top-left-top stop-time" [ngClass]="{ 'late-stop' : baseJob.inThePast }">
          {{ !baseJob.scheduledTime ? '00:00' : baseJob.scheduledTime | amDateFormat:'HH:mm' }}
        </span>
        <span>
          {{ (pickupCount && dropOffCount
            ? 'TITLE.groupedPickupAndDropoff'
            : pickupCount ? 'TITLE.groupedPickup' : 'TITLE.groupedDropoff'
          ) | translate }}
        </span>
      </ion-card-title>

      <ion-card-subtitle>
        <span class="stop-time-remaining">
          <ng-container *ngIf="pickupCount">{{ pickupCount }} {{ (pickupCount > 1 ? 'LABEL.pickups' : 'LABEL.pickup') | translate }}</ng-container>
          <ng-container *ngIf="pickupCount && dropOffCount">, </ng-container>
          <ng-container *ngIf="dropOffCount">{{ dropOffCount }} {{ (dropOffCount > 1 ? 'LABEL.dropOffs' : 'LABEL.dropOff') | translate }}</ng-container>
          <ng-container *ngIf="baseJob.timeToStop"> - {{ baseJob.timeToStop }}</ng-container>
        </span>
      </ion-card-subtitle>

      <div class="badges">
        <ion-badge
          color="medium"
          class="special-assistance-badge"
          *ngFor="let specialAssistance of specialAssistances">
          {{ specialAssistance }}
        </ion-badge>
      </div>
    </div>

    <div class="mobility-icons">
      <ng-container *ngFor="let mobility of mobilities">
        <ng-container [ngSwitch]="mobility">
          <ion-img class="mobility-icon" src="assets/img/Ambulatory.png" *ngSwitchCase="'ambulatory'" [alt]="'MOBILITY.ambulatory' | translate"></ion-img>
          <ion-img class="mobility-icon" src="assets/img/Wheelchair.png" *ngSwitchCase="'wheelchair'" [alt]="'MOBILITY.wheelchairSeats' | translate"></ion-img>
          <ion-img class="mobility-icon" src="assets/img/Stretcher.png" *ngSwitchCase="'stretcher'" [alt]="'MOBILITY.stretcher' | translate"></ion-img>
          <ion-img class="mobility-icon" src="assets/img/Lift.png" *ngSwitchCase="'lift'" [alt]="'MOBILITY.lift' | translate"></ion-img>
        </ng-container>
      </ng-container>
    </div>
  </ion-card-header>

  <ion-card-content>

    <ion-row>
      <ion-col size="12" sizeMd="6" class="job-details">
        <div>
          <address class="address" *ngIf="baseJob?.location">
            <strong>{{ baseJob.location.name }}</strong> - {{ baseJob.location.address }} {{ baseJob.location.address2 }}
            <br>{{ baseJob.location.city }}, {{ baseJob.location.state }}, {{ baseJob.location.zipCode }}
            <ng-container *ngIf="baseJob.jobType == 1 ? !!baseJob.pickupPhone : !!baseJob.dropoffPhone">
              <br>
              <a href="tel:+1{{ baseJob.jobType == 1 ? baseJob.pickupPhone : baseJob.dropoffPhone }}">
                {{ baseJob.jobType == 1 ? baseJob.pickupPhone : baseJob.dropoffPhone }}
              </a>
            </ng-container>
          </address>

          <div *ngIf="!isDetailView" class="detail-note">
            <ion-icon name='expand' />
            {{ 'LABEL.tapForDetails' | translate }}
          </div>
        </div>
      </ion-col>

      <ion-col size="12" sizeMd="6" class="button-list">
        <ng-container *ngIf="showJobActionButtons">
          <ion-button
            type="button"
            *ngIf="showOnTheWayButton"
            expand="block"
            color="primary"
            (click)="onGroupAction('onTheWay', $event)"
          >
            <ion-icon name="send" slot="start"></ion-icon>
            <span>{{ 'ACTION.onTheWay' | translate }}</span>
          </ion-button>

          <ion-button
            type="button"
            *ngIf="showArriveButton"
            expand="block"
            color="primary"
            (click)="onGroupAction('arrive', $event)"
          >
            <ion-icon name="locate" slot="start"></ion-icon>
            <span>{{ 'ACTION.arrive' | translate }}</span>
          </ion-button>

          <ion-button
            type="button"
            *ngIf="showPerform"
            expand="block"
            color="primary"
            (click)="onGroupAction('performGroupedStop', $event)"
          >
            <ion-icon name="trending-down" slot="start"></ion-icon>
            <span>{{ 'LABEL.performGroupedStop' | translate }}</span>
          </ion-button>
        </ng-container>

        <ion-button
          type="button"
          fill="outline"
          expand="block"
          color="primary"
          (click)="onGroupAction('navigate', $event)"
          *ngIf="showNavigateButton"
        >
          <ion-icon name="navigate" slot="start"></ion-icon>
          <span>{{ 'LABEL.navigate' | translate }}</span>
        </ion-button>

          <ion-button
            type="button"
            fill="outline"
            color="medium"
            (click)="onGroupAction('previewLocation', $event);"
          >
            <ion-icon name='map' slot="start"></ion-icon>
            <span class="hidden-md">{{ 'LABEL.seeOn' | translate }}&nbsp;</span>
            <span>{{ 'LABEL.map' | translate }}</span>
          </ion-button>

          <ion-button
            type="button"
            fill="outline"
            expand="block"
            color="medium"
            *ngIf="showNotifyButton"
            (click)="onGroupAction('notify', $event)"
          >
            <ion-icon name="call" slot="start"></ion-icon>
            <span>{{ 'LABEL.notify' | translate }}</span>
          </ion-button>

      </ion-col>
    </ion-row>


  </ion-card-content>
</ion-card>
