import { DriveDetectionMode, InsurancePeriod, Zendrive } from '@capawesome-team/capacitor-zendrive';

import { Capacitor } from "@capacitor/core";
import { Injectable } from "@angular/core";
import { LoggingService } from "../logging.service";
import { TrackJS } from 'trackjs';
import { delay } from 'rxjs';

@Injectable({
  providedIn: "root",
})
/**
 * This service abstracts the custom zendrive/fairmatic plugin.
 * SEE: https://github.com/capawesome-team/capacitor-zendrive
 * The fairmatic dashboard is here: https://app.fairmatic.com/app/dashboard/overview
 */
export class ZendrivePluginService
  implements IZendrivePluginService
{
  private currentPeriod?: ZendriveInsurancePeriod;
  private currentTrackingId?: string;

  constructor(
    protected loggingService: LoggingService,
  ) {}

  /**
   * Returns true is this is running through the web (simulator)
   */
  public get isWebPlatform() {
    return Capacitor.getPlatform() === "web";
  }

  public get isPluginAvailable() {
    return Capacitor.isPluginAvailable('Zendrive') && Zendrive !== undefined;
  }

  /**
   * Setup zendrive with the current application key and driver for fairmatic insurance tracking.
   * @param applicationKey The zendrive/fairmatic application key
   * @param driverId a string that will always be the same for a given driver.
   * @returns an empty promise
   */
  public async setup(applicationKey?: string, driverId?: string): Promise<any> {
    if (this.isWebPlatform) return;

    if (!this.isPluginAvailable) {
      const message = 'Zendrive.setup() - Customer is configured but plugin not available';
      console.warn(message);
      this.loggingService.remoteLog({ message, applicationKey, driverId });
      return;
    } else {
      const message = 'Zendrive.setup() - Customer is configured and plugin is available';
      TrackJS.console.log({ message, applicationKey, driverId });
    }
    await Zendrive.setup({
      applicationKey: applicationKey || 'qIT2nWj1hLNwoa4AcnfQrKNOJ8fS0xz3',
      driveDetectionMode: DriveDetectionMode.Insurance,
      driverId: this.cleanDriverId(driverId || 'UNKNOWN_DRIVER-0'),
    });
  }

  /**
   * Set the zendrive state to Period 1 (OnDuty). This just means they are out and about - driving around. They are not yet on the way to pick up a specific passenger.
   * @returns an empty promise
   */
  public async goOnDuty(): Promise<any> {
    if (this.isWebPlatform || !this.isPluginAvailable) return;

    this.currentTrackingId = undefined;
    await Zendrive.startDriveWithPeriod1();
    this.currentPeriod = ZendriveInsurancePeriod.OnDuty;
  }

  /**
   * Set the status to Off Duty (stop tracking in zendrive)
   * @returns an empty promise
   */
  public async goOffDuty(): Promise<void> {
    if (this.isWebPlatform || !this.isPluginAvailable) return Promise.resolve();
    return Zendrive.stopPeriod();
  }

  /**
   * Set the zendrive state to Period 2 (OnTheWay). This means they have started driving to pick up a specific customer.
   * @param string An Optional tracking number for a passenger that was picked up.
   * @returns an empty promise
   */
  public async acceptPassengerRequest(passengerTrackingNumber?: string): Promise<any> {
    if (this.isWebPlatform || !this.isPluginAvailable) return Promise.resolve();

    const { trackingId } = await this.getActiveDriveInfo();
    const _trackingId = passengerTrackingNumber || trackingId || Date.now().toString();
    return Zendrive.startDriveWithPeriod2({ trackingId: _trackingId });
  }

  /**
   * Set the zendrive state to Period 3 (PassengerOnBoard). This means that they now have one (or many) passengers on board.
   * We don't really track specific customers. Fairmatic just cares if there are people onboard or not for insurance tracking purposes.
   * @param string - An optional tracking number for a passenger that was picked up.
   * @returns An empty promise
   */
  public async pickupPassenger(passengerTrackingNumber?: string): Promise<void> {
    if (this.isWebPlatform || !this.isPluginAvailable) return Promise.resolve();

    const { trackingId, insurancePeriod } = await this.getActiveDriveInfo();
    const _trackingId = passengerTrackingNumber || trackingId || Date.now().toString();
    if (insurancePeriod !== ZendriveInsurancePeriod.OnTheWay) {
       await this.acceptPassengerRequest(passengerTrackingNumber);
    }
    return Zendrive.startDriveWithPeriod3({ trackingId: _trackingId });
  }

  /**
   * Get the current status of the active zendrive session.
   * @returns a promise of the current zendrive status (`ZendriveStatus`: current period and start time, session and tracking ids)
   */
  public async getActiveDriveInfo(): Promise<ZendriveStatus> {
    if (this.isWebPlatform || !this.isPluginAvailable) return { startTimeMillis: Date.now() } as ZendriveStatus;

    const result = await Zendrive.getActiveDriveInfo();

    return {
      insurancePeriod: this.convertPeriod(result.insurancePeriod),
      startTimeMillis: result.startTimeMillis,
      sessionId: result.sessionId,
      trackingId: result.trackingId,
    } as ZendriveStatus;
  }

  /**
   * The fairmatic/zendrive driver id has a limited accepted character set. This cleans out invalid characters.
   * @param driverId string - a driver id to validate and potentially clean
   * @returns string - The driverID with all invalid characters removed.
   */
  public cleanDriverId(driverId: string): string {
    return driverId.replace(/[^a-z0-9-]/gmi, '').trim().toUpperCase();
  }

  /**
   * A utility function to convert the insurance period to a more meaningful enumeration.
   * @param InsurancePeriod - The insurance period from the plugin.
   * @returns ZendriveInsurancePeriod - a more meaningful enum value
   */
  private convertPeriod(insurancePeriod?: InsurancePeriod): (ZendriveInsurancePeriod | undefined) {
    switch (insurancePeriod) {
      case InsurancePeriod.Period1: return ZendriveInsurancePeriod.OnDuty;
      case InsurancePeriod.Period2: return ZendriveInsurancePeriod.OnTheWay;
      case InsurancePeriod.Period3: return ZendriveInsurancePeriod.PassengerOnBoard;
      default: return ZendriveInsurancePeriod.None;
    }
  }
}

export interface IZendrivePluginService {
  setup(applicationKey?: string, driverId?: string): Promise<any>;
  goOnDuty(): Promise<any>;
  goOffDuty(): Promise<any>;
  acceptPassengerRequest(passengerTrackingNumber?: string): Promise<any>;
  pickupPassenger(passengerTrackingNumber?: string): Promise<any>;
  getActiveDriveInfo(): Promise<ZendriveStatus>;
}

export enum ZendriveInsurancePeriod {
  None,
  OnDuty,
  OnTheWay,
  PassengerOnBoard
}

export interface ZendriveStatus {
  insurancePeriod?: ZendriveInsurancePeriod;
  sessionId?: string;
  startTimeMillis: number;
  trackingId?: string;
}
