export enum MessageResponseEnum {
  Unknown = 0,
  None = 1,
  YesNo = 2,
  Acknowledge = 3,
  Numeric = 4
}

export type DispatchMessage = {
  id: number;
  createdDate: Date;
  message: string;
  messageResponseType: MessageResponseEnum;
  responseReceived: boolean;
}
