// <reference types="@types/googlemaps" />
import {Injectable} from '@angular/core';
/*
declare var google;
*/
@Injectable({
    providedIn: 'root',
})
export class GoogleApiService {
    /*
    distMtxSvc = new google.maps.DistanceMatrixService;
    */

    constructor() {
    }

    private isValidFloat(n: number): boolean {
        return n !== null && n !== undefined;
    }

    async calculateETA(lat1: number, lng1: number, lat2: number, lng2: number, fromDate: Date = null): Promise<Date> {
        /*
        if (this.isValidFloat(lat1) && this.isValidFloat(lng1) && this.isValidFloat(lat2) && this.isValidFloat(lng2)) {
            const promise = new Promise<Date>((resolve, reject) => {

                const origin = new google.maps.LatLng(lat1, lng1);
                const destination = new google.maps.LatLng(lat2, lng2);

                this.distMtxSvc.getDistanceMatrix(
                    {
                        origins: [origin],
                        destinations: [destination],
                        travelMode: google.maps.TravelMode.DRIVING,
                        drivingOptions: {
                            departureTime: new Date(),  // for the time N milliseconds from now.
                            trafficModel: google.maps.TrafficModel.BEST_GUESS
                        },
                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                        avoidHighways: false,
                        avoidTolls: false
                    }, function (response, status) {
                        if (status === google.maps.DistanceMatrixStatus.OK) {
                            if (response.rows.length > 0 && response.rows[0].elements.length > 0) {
                                const element = response.rows[0].elements[0];
                                if (element.status === google.maps.DistanceMatrixElementStatus.OK) {
                                    const duration = element.duration;
                                    const valueInSeconds = duration.value;
                                    const eta = fromDate || new Date();
                                    eta.setTime(eta.getTime() + (valueInSeconds * 1000));
                                    console.log(`Google Time to Travel Calculation: ${duration.text} ${eta}`);

                                    resolve(eta);
                                    return;
                                }
                            }

                            reject('Cannot determine distance.');
                        }
                    });
            });
            return await promise;
        }
        */
        return null;
    }
}
