export abstract class AutomatedClickThrough {

  public static clickThroughEnabled = false;

  abstract automateClickThrough(): void;

  ionViewDidEnter(): void {
    if (!AutomatedClickThrough.clickThroughEnabled) { return; }
    this.automateClickThrough();
  }

  startClickThrough(): void {
    AutomatedClickThrough.clickThroughEnabled = true;
    this.automateClickThrough();
  }
}
