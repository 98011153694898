import { Injectable, inject } from '@angular/core';

import { StoragePluginService } from './plugin/storage-plugin.service';

@Injectable({
  providedIn: 'root',
})
export abstract class StorageService<T> {

  protected abstract getStorageKey(): string;
  private storagePluginService = inject(StoragePluginService);

  constructor() {
    this.storagePluginService.ready()
      .then(() => this.storagePluginService.get(this.getStorageKey()));
  }

  protected async getObjectFromStorage(): Promise<T|null> {
    await this.storagePluginService.ready();
    const objString = await this.storagePluginService.get(this.getStorageKey());
    return objString ? JSON.parse(objString) : null;
  }

  protected async saveObjectInStorage(obj: T, storageKey: string = this.getStorageKey()): Promise<T> {
    await this.storagePluginService.ready();
    await this.storagePluginService.set(storageKey, JSON.stringify(obj));
    return obj;
  }

  protected async clearObjectFromStorage(): Promise<void> {
    await this.storagePluginService.ready();
    return this.storagePluginService.remove(this.getStorageKey());
  }
}
