import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, IonContent, ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { TranslateService } from '../../services/translate.service';
import { Job, JobType } from '../../models/job';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.page.html',
  styleUrls: ['./signature.page.scss'],
})
export class SignaturePage implements OnInit, OnDestroy {
  @Input()
  job: Job;

  @ViewChild('refBugWorkAround', { static: true }) signaturePad: SignaturePad;
  @ViewChild('buttonsWrapper', { static: true }) buttonsWrapper: ElementRef;
  @ViewChild('signatureWrapper', { static: true }) signatureWrapper: ElementRef;
  @ViewChild('signaturePageContent', { static: true }) signaturePageContent: IonContent;

  signaturePadOptions: Object = {
    minWidth: 2.5,
    canvasWidth: 600,
    canvasHeight: 280,
    maxWidth: 4.5,
  };

  JobTypeEnum = JobType;
  signature: string;
  signatureReason: string;
  isSignatureDrawn = false;
  didStartSigning = false;
  windowResizeSubscription: Subscription;
  deviceInformation: { portrait: { height: number, width: number }, landscape: { height: number, width: number } };

  constructor(
    public router: Router,
    public zone: NgZone,
    public actionSheetCtrl: ActionSheetController,
    public modalCtrl: ModalController,
    private translationService: TranslateService,
    private platform: Platform,
    private deviceService: DeviceService,
  ) { }

  ngOnInit(): void {
    //  because of a bug in IOS that does not accurately give device height and width after rotation
    if (this.deviceService.isNativeDevice && this.platform.isPortrait()) {
      this.deviceInformation = {
        portrait: { width: this.platform.width(), height: this.platform.height() },
        landscape: { width: this.platform.height(), height: this.platform.width() },
      };
    } else if (this.deviceService.isNativeDevice && this.platform.isLandscape()) {
      this.deviceInformation = {
        landscape: { width: this.platform.width(), height: this.platform.height() },
        portrait: { width: this.platform.height(), height: this.platform.width() },
      };
    }
  }

  ionViewDidEnter() {
    this.signaturePad.clear();
    this.zone.runOutsideAngular(() => {
      this.signaturePad.on();
      this.resizeSignaturePad().then();
    });
  }

  ngOnDestroy() {
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
  }

  async onResize($event: Event) {
    await this.resizeSignaturePad();
  }

  private async resizeSignaturePad() {
    const signaturePad: HTMLElement = (<any>this.signaturePad).elementRef.nativeElement;
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>signaturePad.querySelector('canvas');
    const ctx = canvas.getContext('2d');
    this.trimCanvas(ctx);
    const originalCanvasDimensions = canvas.getBoundingClientRect();
    const signature = canvas.toDataURL();
    const parentCanvasDimensions = signaturePad.parentElement.getBoundingClientRect();

    const originalDirection = originalCanvasDimensions.height === originalCanvasDimensions.width
      ? 'square'
      : originalCanvasDimensions.height > originalCanvasDimensions.width ? 'portrait' : 'landscape';

    const maxSignatureWrapperHeight = 280; // in px
    const padding = 30;
    const buttonsWrapperHeight = this.buttonsWrapper.nativeElement.getBoundingClientRect()?.height;

    const scroll = await this.signaturePageContent.getScrollElement();
    const contentHeight = window.innerHeight - scroll.offsetTop - padding;

    // Resize the signatures parent element
    const targetHeight = Math.min(maxSignatureWrapperHeight, contentHeight - buttonsWrapperHeight);
    const targetWidth = this.deviceService.isNativeDevice ?
      this.platform.isPortrait() ? this.deviceInformation.portrait.width : this.deviceInformation.landscape.width
      : parentCanvasDimensions.width;
    const newDirection = targetHeight === targetWidth
      ? 'square'
      : targetHeight > targetWidth ? 'portrait' : 'landscape';

    //  Determine the change in scale
    const currentCanvasDimensions = canvas.getBoundingClientRect();
    let scale: number;

    if (
      (originalDirection === newDirection && (originalCanvasDimensions.height > targetHeight || originalCanvasDimensions.width > targetWidth))
      || (originalDirection === 'portrait' && newDirection !== 'portrait' && originalCanvasDimensions.height > targetHeight && originalCanvasDimensions.width > targetWidth)
      || (originalDirection === 'landscape' && newDirection !== 'landscape' && originalCanvasDimensions.height > targetHeight && originalCanvasDimensions.width > targetWidth)
    ) {
      scale = Math.min(targetWidth / currentCanvasDimensions.width, targetHeight / currentCanvasDimensions.height);
    } else {
      scale = 1;
    }

    const newSignature = new Image();

    newSignature.onload = () => {
      ctx.drawImage(
        newSignature,
        0, 0, originalCanvasDimensions.width * scale, originalCanvasDimensions.height * scale,
      );
    };

    //  Now resize the signature pad itself
    //  Unfortunately the signature library does not have a built in way to size the canvas to its parent element.
    //  NOTE: there is a bug in IOS where window.width does not return the correct value after rotation, therefore
    //  getting the paren't width is not reliable in IOS.

    this.signatureWrapper.nativeElement.style.height = targetHeight + 'px';

    canvas.width = targetWidth;
    canvas.height = targetHeight;
    newSignature.src = signature;
  }

  /** Crop Canvas to pixes */
  trimCanvas(ctx: CanvasRenderingContext2D) {
    let x: number, y: number, top: number, left: number, right: number, bottom: number;
    let w = ctx.canvas.width;
    let h = ctx.canvas.height;
    if (!w && !h) { return false; }
    const imgData = ctx.getImageData(0, 0, w, h);
    const data = new Uint32Array(imgData.data.buffer);
    let idx1 = 0;
    let idx2 = w * h - 1;
    let found = false;

    // search from top and bottom to find first rows containing a non transparent pixel.
    for (y = 0; y < h && !found; y += 1) {
      for (x = 0; x < w; x += 1) {
        if (data[idx1++] && !top) {
          top = y + 1;
          // top and bottom found then stop the search
          if (bottom) {
            found = true;
            break;
          }
        }
        if (data[idx2--] && !bottom) {
          bottom = h - y - 1;
           // top and bottom found then stop the search
          if (top) {
            found = true;
            break;
          }
        }
      }
      // image is completely blank so do nothing
      if (y > h - y && !top && !bottom) { return false; }
    }

    // correct top
    top -= 1;
    found = false;
    // search from left and right to find first column containing a non transparent pixel.
    for (x = 0; x < w && !found; x += 1) {
      idx1 = top * w + x;
      idx2 = top * w + (w - x - 1);
      for (y = top; y <= bottom; y += 1) {
        if (data[idx1] && !left) {
          left = x + 1;
          if (right) { // if left and right found then stop the search
            found = true;
            break;
          }
        }
        if (data[idx2] && !right) {
          right = w - x - 1;
          if (left) { // if left and right found then stop the search
            found = true;
            break;
          }
        }
        idx1 += w;
        idx2 += w;
      }
    }

    // correct left
    left -= 1;
    // no need to crop if no change in size
    if (w === right - left + 1 && h === bottom - top + 1) { return true; }
    w = right - left + 1;
    h = bottom - top + 1;
    ctx.canvas.width = w;
    ctx.canvas.height = h;
    ctx.putImageData(imgData, -left, -top);
    return true;
  }

  onSignatureDrawStart() {
    this.didStartSigning = true;
  }

  onSignatureDrawComplete() {
    this.isSignatureDrawn = true;
  }

  clearSignatureClicked() {
    this.signaturePad.clear();
    this.isSignatureDrawn = false;
  }

  async captureSignatureAtDropoffClicked() {
    const data = { signature: null, signatureReason: null };
    await this.modalCtrl.dismiss(data);
  }

  async cancelClicked() {
    // await this.pluginService.setToPortrait();
    await this.modalCtrl.dismiss();
  }


  async confirmClicked() {
    // if job type is null then it's a driver signature and we just need to dismiss
    if (!this.job) {
      this.dismiss();
      return;
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translationService.translate("LABEL.confirmRiderSign"),
      cssClass: 'action-sheets-basic-page',
      buttons: [
        {
          text: this.translationService.translate("LABEL.riderSigReceived"),
          icon: 'person',
          handler: () => {
            this.dismiss(this.translationService.translate("LABEL.riderSigReceived"));
          },
          cssClass: 'primary',
        },
        {
          text: this.translationService.translate("LABEL.parentGuardianSig"),
          icon: 'people',
          handler: () => {
            this.dismiss(this.translationService.translate("LABEL.parentGuardianSig"));
          },
          cssClass: 'secondary',
        },
        {
          text: this.translationService.translate("LABEL.riderUnableSig"),
          icon: 'bus',
          handler: () => {
            this.dismiss(this.translationService.translate("LABEL.riderUnableSig"));
          },
          cssClass: 'tertiary',
        },
      ],
    });

    await actionSheet.present();
  }

  private dismiss(signatureReason?: string) {
    this.signature = this.signaturePad.toDataURL();
    this.signatureReason = signatureReason;

    const data = { signature: this.signature, signatureReason: this.signatureReason };
    this.modalCtrl.dismiss(data);
  }
}
