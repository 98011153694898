import { Component, OnInit, inject } from "@angular/core";
import { Job, JobType, buildJobPerform } from "src/app/models/job";

import { JobDetailsPage } from "src/app/pages/job-details/job-details.page";
import { JobPerformPage } from "src/app/pages/job-perform/job-perform.page";
import { ModalController } from "@ionic/angular";
import { NoShowReasonEnum } from "src/app/models/no-show-option";
import { NotificationService } from "src/app/services/notification.service";
import { ScheduledEvent } from "../../job-list/job-list.component";
import { TranslateService } from "src/app/services/translate.service";
import { UtilityService } from "src/app/services/utility.service";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: './group-perform.dialog.html',
  styleUrls: [ './group-perform.dialog.scss' ],
})
export class GroupPerformDialog {

  private readonly modalController = inject(ModalController);
  private readonly utility = inject(UtilityService);
  private readonly notify = inject(NotificationService);
  private readonly translate = inject(TranslateService);

  public readonly jobTypes = JobType;
  private set jobs (jobs: ScheduledEvent<Job>[]) {
    this.pickups = jobs.filter(j => j.jobType === JobType.Pickup);
    this.dropOffs = jobs.filter(j => j.jobType === JobType.DropOff);
  }
  public pickups: ScheduledEvent<Job>[] = [];
  public dropOffs: ScheduledEvent<Job>[] = [];

  public async showJobDetails(job: Job) {
    const detailModal = await this.modalController.create({
      component: JobDetailsPage,
      backdropDismiss: false,
      componentProps: { job, isDetailView: true },
    });
    await detailModal.present();
    const { data: { rideId, jobPerformed }} = await detailModal.onDidDismiss() || { data: { }};
    if (jobPerformed && rideId) { this.syncView(job); }
  }

  public async handleNoShow(job: Job) { await this.performJob(job, null, null, NoShowReasonEnum.NoClient); }
  public async handleOnBoard(job: Job) { await this.performJob(job, null, null); }
  public async handleDropOff(job: Job) { await this.performJob(job, null, null); }

  private async performJob(job: Job, signature: string, signatureReason: string, noShowReason?: NoShowReasonEnum) {
    try {
      const jobPerform = buildJobPerform({ job, signature, signatureReason, noShowReason });
      const performModal = await this.modalController.create({
        component: JobPerformPage,
        backdropDismiss: false,
        componentProps: { job, jobPerform },
      });
      await performModal.present();
      await performModal.onDidDismiss();
      this.syncView(job);
    } catch (err) {
      console.error(err);
      this.notify.error(this.translate.translate('MESSAGE.genericError'), 3000, true);
    }
  }

  private syncView(job: Job) {
    if (job.jobType === this.jobTypes.Pickup) { this.pickups = this.pickups.filter(j => j.rideId !== job.rideId); }
    if (job.jobType === this.jobTypes.DropOff) { this.dropOffs = this.dropOffs.filter(j => j.rideId !== job.rideId); }
    if (environment.features.onlyAtNextLocationEnabled) {
      this.utility.scrollTop$.next(true);
    }
    if (!this.pickups.length && !this.dropOffs.length) { this.modalController.dismiss(); }
  }

  public handleName(first: string, last: string): string {
    return this.utility.handleName(first, last);
  }

  public onCancel(): void {
    this.modalController.dismiss();
  }

}
