import { AfterContentInit, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';
import { DeviceService } from 'src/app/services/device.service';
@Component({
  selector: '[incrementControl]',
  templateUrl: `./increment-control.component.html`,
  styleUrls: [`./increment-control.component.scss`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncrementalControlComponent),
      multi: true,
    },
  ],
})
export class IncrementalControlComponent implements ControlValueAccessor, AfterContentInit {
  @Input() incName: string;
  @Input() model: number;
  @Input() showOutline: boolean;
  @Input() maxValue: number;
  @Input() minValue: number;
  @Input() integerOnly: boolean;
  @Input() allowNull = false;
  @Input() disabled = false;
  @Input() labelledBy: string | undefined;

  previousModel: number;
  form: UntypedFormGroup;

  propagateChange: (_: any) => void = (_) => { }; // tslint:disable-line

  constructor(
    private deviceService: DeviceService,
  ) { }

  ngAfterContentInit() {
    this.minValue = this.minValue || 0;
    this.model = this.model || this.minValue || 0;
    this.previousModel = this.model;
  }

  writeValue(value: any) {
    this.model = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  increaseClicked() {
    this.model++;
    if (this.maxValue && this.maxValue < this.model) {
      this.model = this.maxValue;
    }
    this.propagateChange(+this.model);
    this.previousModel = +this.model;
    if (this.deviceService.isNativeDevice) {
      Keyboard.hide();
    }
  }

  decreaseClicked() {
    this.model--;
    if (this.model < this.minValue) {
      this.model = this.minValue;
    }
    this.propagateChange(+this.model);
    this.previousModel = +this.model;
    if (this.deviceService.isNativeDevice) {
      Keyboard.hide();
    }
  }

  modelChanged() {
    let changeTo: any = this.model;
    if (!changeTo) {
      // Do nothing
    } else {
      const isValid = !isNaN(parseFloat(changeTo)) && isFinite(changeTo);
      if (!isValid) {
        changeTo = this.previousModel;
      }
      if (this.model < 0) {
        changeTo = this.previousModel;
      }
      if (this.maxValue && this.maxValue < +changeTo) {
        changeTo = this.previousModel;
      }
      if (this.minValue && this.minValue > +changeTo) {
        changeTo = this.minValue;
      }

      // Discard decimal if integerOnly = true. eg. for number of children.
      if (this.integerOnly) {
        changeTo = Math.floor(changeTo);
      }

      // Check for more than two decimals
      let changeToString = '' + (changeTo || 0);
      const indexOfDot = changeToString.indexOf('.');
      if (indexOfDot > -1) {
        const lengthOfStringAfterDot = changeToString.substring(indexOfDot).length;
        if (lengthOfStringAfterDot > 3) {
          changeToString = changeToString.substring(0, indexOfDot + 3);
          changeTo = +changeToString;
        }
      }
    }

    setTimeout(() => {
      this.model = changeTo;
      this.propagateChange(+this.model);
      this.previousModel = +this.model;
    }, 1);
  }

  onEventInputChange($event) {
    const val = $event.target.value;
    if (this.maxValue && +val > this.maxValue) {
      $event.target.value = this.maxValue;
    }
    if (this.minValue && +val < this.minValue) {
      $event.target.value = this.minValue;
    }
  }

  onBlur() {
    if (!this.allowNull) {
      this.model = parseFloat(<any>this.model);
      if (isNaN(this.model)) {
        this.model = this.minValue || 0;
      }
    }
    this.previousModel = this.model;
  }
}
