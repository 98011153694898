import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SafeHttpService {

    constructor(private http: HttpClient) {
    }

    get<T>(url: string, options?: HttpRequestOptions, timeoutMS?: number): Promise<T> {
        if (timeoutMS) {
            options = options || new HttpRequestOptions();
            options.headers = new HttpHeaders({ timeout: `${timeoutMS}` });
        }
        return this.http.get<T>(url, options)
            .toPromise();
    }

    getAsync<T>(url: string, options?: HttpRequestOptions, timeoutMS?: number): Observable<T> {
        if (timeoutMS) {
            options = options || new HttpRequestOptions();
            options.headers = new HttpHeaders({ timeout: `${timeoutMS}` });
        }
        return this.http.get<T>(url, options);
    }

    post<T>(url: string, body: any, options?: HttpRequestOptions, timeoutMS?: number): Promise<T> {
        if (timeoutMS) {
            options = options || new HttpRequestOptions();
            options.headers = new HttpHeaders({ timeout: `${timeoutMS}` });
        }
        return this.http.post<T>(url, body, options)
            .toPromise();
    }

    postAsync<T>(url: string, body: any, options?: HttpRequestOptions, timeoutMS?: number): Observable<T> {
        if (timeoutMS) {
            options = options || new HttpRequestOptions();
            options.headers = new HttpHeaders({ timeout: `${timeoutMS}` });
        }
        return this.http.post<T>(url, body, options);
    }

}

export class HttpRequestOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}
