import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { Network } from '@capacitor/network';
import { NotificationService } from './notification.service';
import { TranslateService } from './translate.service';
import { firstValueFrom } from 'rxjs';

export const ERROR_STRINGS = {
  Unknown: 'Sorry, there was an unknown error.',
  RegisterInvalidCustomerName: 'Cannot register device, error: Invalid provider name.',
  RegisterMissingDescription: 'Cannot register device, error: Missing description.',
  RegisterUnknown: 'Cannot register device.',
  Timeout: 'The request has timed out, please try again.',
  ServerError: 'There was an unexpected error. Tech support has been notified',
  RequestError: 'There was an error in the request.',
  HttpErrorResponse: 'HttpErrorResponse',
  NetworkError: 'There was a network error. Please check your connection and try again.',
};

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
  }

  handleError(error: Error, silent?: boolean) {
    console.warn('handled error in global error handler', error);
    if (silent) { return; }
    const defaultError = this.translateService.translate('MESSAGE.genericError');
    this.notificationService.error(defaultError);
  }

  handleHttpError(error: ExtendedError) {

    if (error.error && error.error !== undefined) {
      this.notificationService.warning(error.error.CustomerName[0]);
    }
    else if (this.matchError(error, ERROR_STRINGS.ServerError)) {
      // special handling here
      this.notificationService.warning(ERROR_STRINGS.ServerError);
      console.error('The server returned an error on Login', error);
    } else if (
      this.matchError(error, ERROR_STRINGS.RequestError)
      || this.matchError(error, ERROR_STRINGS.HttpErrorResponse)
    ) {
      console.error(error.message);
      this.notificationService.warning(ERROR_STRINGS.RequestError);
    } else if (
      error.message && error.message.toLowerCase().startsWith(`http failure response for`)
      || this.matchError(error, ERROR_STRINGS.NetworkError)
    ) {
      this.notificationService.warning(ERROR_STRINGS.NetworkError);
      console.error('An http failure occurred.', error);
    } else {
      this.handleError(error);
    }
  }

  matchError(e: Error, errorType) {
    return e.name === this.getErrorName(errorType);
  }

  createError(errorType): Error {
    const error = new Error(errorType);
    error.name = this.getErrorName(errorType);
    return error;
  }

  getErrorName(error): string {
    for (const i in ERROR_STRINGS) {
      if (ERROR_STRINGS[i] === error) {
        return i;
      }
    }
    return 'Unknown';
  }
}
///for API validation messages, must send ModelValidation error property "ValidationError" :["something"]
///put it here cause ¯\_(ツ)_/¯
class ExtendedError extends Error {
  error: { [key: string]: {} };
}
