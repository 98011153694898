import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './services/timeout-interceptor';
import { FormsModule, UntypedFormBuilder } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { DeviceService } from './services/device.service';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { HomePageModule } from './pages/home/home.module';
import { HttpMockRequestInterceptor } from './services/mock-interceptor';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { LoggingService } from './services/logging.service';
import { PowerManagement } from '@awesome-cordova-plugins/power-management/ngx';
import { RouteReuseStrategy } from '@angular/router';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SharedModule } from './shared/shared.module';
import { Storage } from '@ionic/storage';
import { StoragePluginService } from './services/plugin/storage-plugin.service';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from './services/translate.service';
import { UtilityService } from './services/utility.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    HomePageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    IonicModule.forRoot({
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
  ],
  exports: [],
  providers: [
    Deploy,
    Diagnostic,
    LaunchNavigator,
    BarcodeScanner,
    PowerManagement,
    BackgroundMode,
    ScreenOrientation,
    DecimalPipe,
    DatePipe,
    Storage,
    UntypedFormBuilder,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [StoragePluginService, LoggingService, UtilityService, TranslateService, DeviceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpMockRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 50000 },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

function initializeAppFactory(
  translateService: TranslateService,
): () => Promise<void> {
  return async () => {
    console.info('initializeAppFactory called, bootstrapping the app');
    //there may be more things we need to do here to initialize all of our services

  };
}
