<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'TITLE.selectServer' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="padding">
  <form [formGroup]="form" id="environmentPicker" (submit)="onSubmit()">
    <ion-radio-group formControlName="environment">
      <ion-item *ngFor="let env of environments">
        <ion-label>{{ env.name }}</ion-label>
        <ion-radio slot="end" [value]="env.url"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </form>
</ion-content>

<ion-footer>
  <ion-button type="submit" form="environmentPicker" color="primary" fill="solid" expand="block">
    {{ 'ACTION.ok' | translate }}
  </ion-button>
  <ion-button type="button" fill="outline" expand="block" (click)="onCancel()">{{ 'ACTION.cancel' | translate }}</ion-button>
</ion-footer>
