import {Injectable} from '@angular/core';

export const MilesToKilometersMultiplier = 1.609344;
export const MilesToMetersMultiplier = 1609.344;
export const MetersPerSecToMilesPerHrMultiplier = 2.23694;

@Injectable({
    providedIn: 'root',
})
export class LocationUtilityService {
    /**
     * Finds the euclidean distance in kilometers between two points
     *
     * @param lat1 - The latitude of the first position
     * @param lng1 - The longitude of the first position
     * @param lat2 - The latitude of the second position
     * @param lng2 - The longitude of the second position
     *
     * @return int - The distance in kilometers
     */
    public static euclideanDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
        if (!lat1 || !lng1 || !lat2 || !lng2) {
            // if any of these are not defined or are zero then return null because we can't calculate
            return null;
        }

        const R = 6371; // km
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lng2 - lng1) * Math.PI / 180;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    }

    /**
     * Returns the distance in miles between two geocoordinates.
     *
     * @param lat1 - The latitude of the first position
     * @param lng1 - The longitude of the first position
     * @param lat2 - The latitude of the second position
     * @param lng2 - The longitude of the second position
     */
    public static euclideanDistanceInMiles(lat1: number, lng1: number, lat2: number, lng2: number): number {
        const distanceInKm = LocationUtilityService.euclideanDistance(lat1, lng1, lat2, lng2);
        if (distanceInKm === null) {
            return null;
        }
        return distanceInKm / MilesToKilometersMultiplier;
    }

    /**
     * Calculate the bearing between two positions as a value from 0-360
     *
     * @param lat1 - The latitude of the first position
     * @param lng1 - The longitude of the first position
     * @param lat2 - The latitude of the second position
     * @param lng2 - The longitude of the second position
     *
     * @return int - The bearing between 0 and 360
     */
    public static bearing(lat1: number, lng1: number, lat2: number, lng2: number): number {
        const dLon = (lng2 - lng1);
        const y = Math.sin(dLon) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        const bearingInDegrees = Math.atan2(y, x) * 180 / Math.PI; // convert radians to degrees
        return 360 - ((bearingInDegrees + 360) % 360);
    }

    /**
     * Finds the difference in hours between two dates.
     * @param d1 - date (minuend)
     * @param d2 - date to subtract (subtrahend)
     */
    public static hoursDiff(d1, d2): number {
        return Math.abs(d1 - d2) / 36e5;
    }
}
