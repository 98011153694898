<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()" size="default" color="secondary" fill="outline">
        <ion-icon name="arrow-back"></ion-icon>
        <span class="hidden-md">&nbsp; {{ 'ACTION.back' | translate }}</span>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'TITLE.verifyOdometer' | translate }} {{ routeSummary.vehicleNumber }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item class="ion-margin-top">
    <div class="odometer">
      {{ 'LABEL.odometer' | translate }}
      <div
        class="incrementer"
        slot="end"
        incrementControl
        [maxValue]="999999"
        [incName]="'LABEL.odometer' | translate"
        [(ngModel)]="tempOdometer"
        [integerOnly]="true"
        [showOutline]="true">
      </div>
    </div>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-button
    color="primary"
    expand="block"
    (click)="submitOdometer()"
    [disabled]="false">
    {{'ACTION.submit' | translate}}
    <ion-icon name="checkmark" slot="end"></ion-icon>
  </ion-button>
</ion-footer>
