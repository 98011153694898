import { Component, Input, inject } from '@angular/core';

import { Job } from '../../models/job';
import { TranslateService } from '../../services/translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: '[app-job-card-general]',
  templateUrl: './job-card-general.html',
  styleUrls: ['./job-card-general.scss'],
})
export class JobCardGeneralComponent {
  private readonly translateService = inject(TranslateService);
  @Input() job: Job;
  showClientId: boolean = environment.features.showClientId;

  jobTypeLabel(): string {
    return this.translateService.translate(this.job.jobType == 1 ? 'LABEL.pickup' : 'LABEL.dropoffNoSpace');
  }
}
