import { Pipe, PipeTransform } from "@angular/core";

export enum NoShowReasonEnum {
  NoClient = 1,
  CancelAtDoor = 2,
  NotReady = 3,
  AdminCancel = 4,
  NotReadyWillReturn = 5,
  DeniedByDriver = 6,
}

export const NoShowReasons: Record<NoShowReasonEnum, string> = {
  [NoShowReasonEnum.NoClient]: 'NO_SHOW_REASON.noClient',
  [NoShowReasonEnum.CancelAtDoor]: 'NO_SHOW_REASON.cancelAtDoor',
  [NoShowReasonEnum.NotReady]: 'NO_SHOW_REASON.notReady',
  [NoShowReasonEnum.AdminCancel]: 'NO_SHOW_REASON.adminCancel',
  [NoShowReasonEnum.NotReadyWillReturn]: 'NO_SHOW_REASON.notReadyWillReturn',
  [NoShowReasonEnum.DeniedByDriver]: 'Denied by driver',
} as const;
export type NoShowReasons = typeof NoShowReasons
export type NoShowReason = typeof NoShowReasons[NoShowReasonEnum]
export type NoShowReasonValue = keyof NoShowReasons


@Pipe({ standalone: true, name: 'noShowReason' })
export class NoShowReasonPipe implements PipeTransform  {
  transform(value: NoShowReasonEnum): string {
    return NoShowReasons[NoShowReasonEnum[value]] || value;
  }
}

export type NoShowOptions = {
  id: number;
  displayValue: string;
  createsCopyOfRide: boolean;
}
